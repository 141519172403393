<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.gestion && $root.data.accessList.gestion.periods && $root.data.accessList.gestion.periods.active))">

      <div class="card shadow" style="margin-top: 85px!important;">
        
          <Headboard 
            :title="'Gestión de Períodos'"
            :icon-reference="'fas fa-calendar-week'"
            :loading="sending"
            :hideFilters="true"
            :minimalist="$root.data.minimalist"
            :create-action-name="'Nuevo Período'"
            :permission="'READ'"
            @handler-refresh="handlerRefresh"
          />

          <div class="card-body container-pager-grid">
              <Pager 
                :pagination="pagination"
                :records-count="records.length"
                @handler-previous-page="handlerPreviousPage"
                @handler-next-page="handlerNextPage"
              />

              <CustomGrid
                :visible="!sending"
                :titles="grid.titles"
                :mapping="grid.mapping"
                :condensed="true"
                :records="records"
                :pagination="pagination"
                :permission="$root.data.accessList.gestion.periods.permission"
                @handler-show-status-change="handlerShowStatusChange"
                @handler-view-only-read-mode="handlerViewOnlyReadMode"
                @handler-close-period-only-read-mode="closePeriodOnlyReadMode"
              />              
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <StatusChangeForm :visible="showStatusChange" :item="documentObj" @handler-hide-status-change="handlerHideStatusChange" @handler-status-change="handlerStatusChange" />
      <PeriodOnlyReadModeForm :visible="showPeriodOnlyReadMode" :item="documentObj" @handler-hide="handlerHideViewOnlyReadMode" @handler-accepted="viewPeriodOnlyReadMode" />
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import Pager from '../../../Widgets/Pager.vue'
import CustomGrid from '../../../Widgets/CustomGrid.vue'
import StatusChangeForm from './Forms/StatusChangeForm.vue'
import PeriodOnlyReadModeForm from './Forms/OnlyReadModeForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
import UtilFunctions from '../../../../utils/index'

export default {
    name: 'Areas',
    components: {
      Headboard,
      Pager,
      CustomGrid,
      PeriodOnlyReadModeForm,
      StatusChangeForm,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        roles: [],
        records: [],
        documentObj: {
          id: '',
          from: '',
          to: '',
          status: '',
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        hide: false,
        sending: false,
        showStatusChange: false,
        showPeriodOnlyReadMode: false,
        waitingNewCurrentPeriodToSaveIt: false,
        pagination: {
          currentStart: 0,
          currentEnd: 0,
          total: 0,
          currentPage: 1,
          totalPage: 1,
          limit: 20
        },
        grid: {
          titles: [{name: 'Año', center: true}, {name: 'Desde'}, {name: 'Hasta'}, {name: 'Estados', center: false}, {name: 'Acciones', center: true}],
          mapping: [
            {
              mode: 'MULTI_FIELDS',
              ref: 'time',
              center: true,
              refs: [{
                title: 'Año del período',
                name: 'year',
                iconClass: 'far fa-calendar pr-1 color-gris-intermedio'
              }]
            },
            {
              mode: 'MULTI_FIELDS',
              ref: 'time',
              refs: [{
                title: 'Fecha de inicio de período',
                name: 'from',
                iconClass: 'fas fa-calendar-alt pr-1 color-gris-intermedio'
              }]
            },
            {
              mode: 'MULTI_FIELDS',
              ref: 'time',
              refs: [{
                title: 'Fecha de cierre de período',
                name: 'to',
                iconClass: 'fas fa-calendar-alt pr-1 color-gris-intermedio'
              }]
            },
            { 
              mode: 'OPTIONS',
              ref: 'transformedStatus',
              center: true,
              class: {
                ABIERTO: 'badge-customized badge-customized-success',
                CERRADO: 'badge-customized badge-customized-danger',
                ACTUAL: 'badge-customized badge-customized-success',
                LECTURA: 'badge-customized badge-customized-info'
              }
            },
            {
              mode: 'ACTIONS',
              center: false,
              actions: [
                {
                  type: 'EXIT',
                  tooltip: 'Cerrar período',
                  handler: 'handler-show-status-change'
                },
                {
                  type: 'VIEW',
                  tooltip: 'Abrir el período en modo lectura',
                  handler: 'handler-view-only-read-mode'
                },
                {
                  type: 'NOT_VIEW',
                  tooltip: 'Cerrar el período en modo lectura',
                  handler: 'handler-close-period-only-read-mode'
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.sending = false
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.pagination.currentPage = callback.response.currentPage
          this.pagination.totalPages = callback.response.totalPages
          this.pagination.currentStart = ((this.pagination.limit * (this.pagination.currentPage - 1)) + 1)
          this.pagination.currentEnd = ((this.pagination.limit * (this.pagination.currentPage - 1)) + callback.response.records.length)
          this.pagination.total = callback.response.totalRecords
          this.records = callback.response.records
          if (this.waitingNewCurrentPeriodToSaveIt) this.saveNewCurrentPeriod(this.records[0]);
        } else {
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.pagination.currentPage = 1
        this.consultBuilder()
      },
      handlerPreviousPage() {
        this.pagination.currentPage -= 1
        this.consultBuilder()
      },
      handlerNextPage() {
        this.pagination.currentPage += 1
        this.consultBuilder()
      },
      handlerViewOnlyReadMode(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          from: (item && item.from) ? item.from : '',
          to: (item && item.to) ? item.to : ''
        }
        this.showPeriodOnlyReadMode = true
      },
      handlerHideViewOnlyReadMode() {
        this.showPeriodOnlyReadMode = false
      },
      viewPeriodOnlyReadMode() {
        this.handlerHideViewOnlyReadMode()
        this.savePeriodOnlyReadMode()
      },
      handlerShowStatusChange(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : ''
        }
        this.showStatusChange = true
      },
      handlerHideStatusChange() {
        this.showStatusChange = false
      },
      handlerStatusChange() {
        this.handlerHideStatusChange()
        this.statusChangeBuilder(this.documentObj)
      },
      savePeriodOnlyReadMode() {
        const previousPeriodInfo = {
          year: UtilFunctions.getYearFromDateStr(this.documentObj.from),
          from: UtilFunctions.showDateFormatMoreHuman(this.documentObj.from),
          to: UtilFunctions.showDateFormatMoreHuman(this.documentObj.to)
        }
        localStorage.periodOnlyReadMode = this.documentObj.id;
        localStorage.previousPeriodInfo = JSON.stringify(previousPeriodInfo);
        this.consultBuilder();
      },
      closePeriodOnlyReadMode() {
        localStorage.removeItem('periodOnlyReadMode');
        localStorage.removeItem('previousPeriodInfo');
        this.consultBuilder();
      },
      
      consultBuilder() {
        this.hide = true
        const requestBodyElement = {
          page: this.pagination.currentPage,
          limit: this.pagination.limit
        }
        if (localStorage.periodOnlyReadMode && localStorage.periodOnlyReadMode !== undefined) {
          requestBodyElement['selectedPeriodOnlyReadMode'] = localStorage.periodOnlyReadMode;
        }
        this.httpConsumerData = {
          endpoint: '/period/manager/list',
          method: 'post',
          withConfirmation: false,
          requestBody: requestBodyElement
        }
        this.sending = true
      },

      statusChangeBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: `/period/manager/close/${item.id}`,
          method: 'put',
          withConfirmation: true,
          requestBody: {}
        }
        this.waitingNewCurrentPeriodToSaveIt = true;

        this.sending = true
      },

      saveNewCurrentPeriod(obj) {
        const currentPeriodInfo = {
          year: UtilFunctions.getYearFromDateStr(obj.from),
          from: UtilFunctions.showDateFormatMoreHuman(obj.from),
          to: UtilFunctions.showDateFormatMoreHuman(obj.to)
        }
        localStorage.currentPeriodInfo = JSON.stringify(currentPeriodInfo);
      }

    }
}
</script>

<style>

</style>

<template>
  <div>
    <!-- Topbar -->
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow customized" style="background: #000;">
      <!-- Sidebar Toggle (Topbar) -->
      <img v-if="(!$root.data.company.logo)"
          class="rounded-lg"
          style="object-fit: cover; border: 0px solid black; width: 78px; height: 76px; margin-left: -12px; margin-top: -1px; border: 1px solid #756f6f; background-image: linear-gradient(180deg, #ffffff 0, #d4fbff 25%, #9dd5f2 50%, #63b0d7 75%, #1e90c0 100%);"
          src="../../../assets/company.png" alt="Logo de Empresa"/>
      
      <img v-else
          class="rounded-lg"
          style="object-fit: cover; border: 0px solid black; width: 78px; height: 76px; margin-left: -12px; margin-top: -1px; border: 1px solid #756f6f; background-image: linear-gradient(180deg, #ffffff 0, #d4fbff 25%, #9dd5f2 50%, #63b0d7 75%, #1e90c0 100%);"
          :src="$root.data.company.logo" alt="Logo de Empresa"/>

      <table class="company-info">
        <thead>
          <tr>
            <td class="color-gris-normal" style="padding-left: 5px; font-size: 14px;"><b>{{($root.data.company.name !== '') ? $root.data.company.name : '-'}}</b></td>
          </tr>
          <tr>
            <td class="color-gris-normal" style="padding-left: 5px; font-size: 14px;">{{($root.data.company.nit !== '') ? 'Nit: ' + $root.data.company.nit : '-'}}</td>
          </tr>
        </thead>
      </table>
      
      <button v-if="$root.data.role === 'MASTER'" class="btn btn-sm btn-outline-primary ml-3 company-info-edit" style="height: 22px; font-size: 75%; top: 0px; padding-top: 0px; margin-top: -50px;" @click="companyModalAction()">
        <i class="fas fa-pencil-alt"></i> Editar empresa
      </button>
      
      <!-- Topbar Navbar -->
      <ul class="navbar-nav ml-auto">
        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="mr-2 d-lg-inline text-gray-600 small username-info">
              <!--<i v-if="$root.role === 'MASTER'" title="Usted es un usuario Master" class="fas fa-crown" style="color: #eacf0d"></i>&nbsp;&nbsp;&nbsp;-->
              <span v-if="$root.role === 'MASTER'" class="badge badge-pill username-info-role" style="background: #cead09; color: #ffffff;" title="Usted es un usuario administrador">MASTER</span>
              <span v-else class="badge text-uppercase username-info-role">{{$root.data.role}}</span>
              <b class="pl-1 username-info-fullname" style="color: #756f6f;">{{$root.data.fullname}}</b>
            </div>
            <img
              class="img-profile rounded-circle auth-avatar"
              :src="(!$root.data.photo) ? getImageUrl('user-avatar.png') : $root.data.photo"
            />
          </a>
          <!-- Dropdown - User Information -->
          <div
            class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <a
              @click="avatarModalAction()"
              class="dropdown-item color-gris-normal"
              style="cursor: pointer;"
            >
              <i class="fas fa-portrait fa-sm fa-fw mr-2 color-gris-intermedio"></i>
              Cambiar foto
            </a>
            <a
              @click="passwordModalAction()"
              class="dropdown-item mt-1"
              style="cursor: pointer;"
            >
              <i class="fas fa-key fa-sm fa-fw mr-2 color-gris-intermedio"></i>
              Cambiar contraseña
            </a>
            <a
              @click="logOut()"
              class="dropdown-item mt-1"
              style="cursor: pointer;"
              href="#"
            >
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 color-rojo-intermedio"></i>
              Cerrar sesión
            </a>
          </div>
        </li>
      </ul>
    </nav>
    <!-- End of Topbar -->

  </div>
</template>

<script>
export default {
    name: 'TopBar',
    methods: {
        logOut() {
            this.$emit('logout-action')
        },
        companyModalAction() {
          this.$emit('companyModalAction')
        },
        avatarModalAction() {
          this.$emit('avatarModalAction')
        },
        passwordModalAction() {
          this.$emit('passwordModalAction')
        },
        getImageUrl(imageName) {
            let images = require.context('../../../assets/menu/', false, /\.png$/);
            return images('./' + imageName);
        },
    }
}
</script>

<style scoped>
  .customized {
    position: fixed;
    margin-left: 20%;
    width: 80%;
    height: 80px;
    background: #000;
    z-index: 10;
  }
  .username-info {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .username-info-role {
    background: #273370;
    color: rgb(252, 252, 199);
  }
  .auth-avatar {
    object-fit: cover; 
    border: 1px solid #756f6f; 
    width: 55px !important; 
    height: 55px !important; 
    background-image: radial-gradient(circle at 100% 0%, #9bd7fc 0, #b9f4f9 25%, #cef0f9 50%, #539fba 75%, #2da2dc 100%);
  }

  @media screen and (max-width: 700px) {
    .company-info-title {
      display: none;
    }
    .company-info-edit > span {
      display: none;
    }
  }
  
  @media screen and (max-width: 600px) {
    .company-info {
      display: none;
    }
    .company-info-edit > span {
      display: none;
    }
    .username-info {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media screen and (max-width: 400px) {
    .company-info {
      display: none;
    }
    .company-info-edit > span {
      display: none;
    }
    .username-info-fullname {
      display: none;
    }
  }
</style>
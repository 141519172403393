<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.generalParameters && $root.data.accessList.generalParameters.hoursType && $root.data.accessList.generalParameters.hoursType.active))">

      <div class="card shadow mb-2" style="margin-top: 85px!important;">
        
          <Headboard 
            :title="'Configuración de Tipos de Horas'"
            :icon-reference="'far fa-clock'"
            :loading="sending"
            :hideFilters="true"
            :minimalist="$root.data.minimalist"
            :permission="'READ'"
            :customized-button="{
              text: 'Guardar Cambios',
              class: 'btn btn-success btn-sm',
              iconClass: 'fas fa-save',
              handler: 'handler-save',
              title: 'Guardar Cambios',
              visibility: customButtonVisibility
            }"
            @handler-save="handlerSave"
            @handler-refresh="handlerRefresh"
          />

      </div>

      <div class="card shadow mb-4" :class="{'pb-2': (sending)}">
        <div v-if="!sending" class="card-body py-2">
          <SavedForm :hourTypes="documentObj.hourTypes" @handler-toggle-edition="handlerToggleEdition" />
        </div>
        <Loading v-else />
      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import Loading from '../../../Widgets/Loading.vue'
import SavedForm from './Forms/SavedForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
export default {
    name: 'Groups',
    components: {
      Headboard,
      Loading,
      SavedForm,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        roles: [],
        documentObj: {
          hourTypes: []
        },
        filters: {
          code: '',
          name: '',
          status: ''
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        customButtonVisibility: 'INVISIBLE',
        hide: false,
        sending: false
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.sending = false
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.documentObj.hourTypes = callback.response.records
        } else {
          this.customButtonVisibility = 'INVISIBLE'
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.consultBuilder()
      },
      handlerToggleEdition(obj) {
        this.documentObj.hourTypes.forEach((h, i, a) => {
          if (obj.type === h.type) {
            a[i].isEditable = !h.isEditable
          }
        })
        this.customButtonVisibility = this.isEditionMode() ? 'VISIBLE' : 'INVISIBLE'
      },
      handlerSave() {
        this.saveBuilder()
      },
      isEditionMode() {
        return this.documentObj.hourTypes.some((h) => {
          if (h.isEditable) {
            return true
          }
        })
      },

      consultBuilder() {
        this.hide = true
        this.httpConsumerData = {
          endpoint: '/hourType/manager/list',
          method: 'get',
          withConfirmation: false,
          requestBody: {}
        }
        this.sending = true
      },

      saveBuilder() {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/hourType/manager/',
          method: 'put',
          withConfirmation: true,
          requestBody: {
            hourTypes: this.documentObj.hourTypes
          }
        }

        this.sending = true
      }

    }
}
</script>

<style>

</style>
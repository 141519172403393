<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content scroll-y-custom-2">
                            <form>
                                <div class="modal-body">
                                    <p class="color-gris-intermedio">
                                        <b class="color-gris">Edición de Horas Laborales</b>
                                    </p>

                                    <div class="card p-3">
                                        <div class="row" style="font-size: 85%; margin-top: -8px;">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <i class="fas fa-user pr-1 color-gris-normal"></i>
                                                    <span>{{item.employee.fullname}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" style="font-size: 85%; margin-top: -8px;">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <i class="fas fa-id-card pr-1 color-gris-normal"></i>
                                                    <span>{{item.employee.idNumber}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <ClassificationForm 
                                        :item="item"
                                        :cost-centers="costCenters" 
                                        :projects="projects"
                                        />

                                        <TimesForm
                                        :item="item"
                                        :period="period"
                                        @handler-add-time-range="handlerAddTimeRange"
                                        @handler-update-time-range="handlerUpdateTimeRange"
                                        @handler-remove-time-range="handlerRemoveTimeRange"
                                        />
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onSave()" type="submit" class="btn btn-success btn-sm">Guardar cambios</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
import ClassificationForm from './SavedForm/Classification.vue'
import TimesForm from './SavedForm/Times.vue'
export default {
    name: 'HoursRegistryEditionForm',
    components: {
        ClassificationForm,
        TimesForm,
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        },
        costCenters: {
            type: Array,
            default() { return [] }
        },
        projects: {
            type: Array,
            default() { return [] }
        },
        period: {
            type: Object,
            required: true
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-hide-edition-form')
        },
        onSave() {
            this.$emit('handler-edit-registry');
        },
        getCurrentDate() {
            const d = new Date();
            return d.toISOString().substr(0, 10)
        },
        isInsidePeriod() {
            const currentDate = new Date();
            const fromDate = new Date(`${this.period.from} 00:00`);
            const toDate = new Date(`${this.period.to} 23:59`);
            
            return (currentDate.getTime() >= fromDate.getTime() && currentDate.getTime() <= toDate.getTime())
        },
        setSelectedTab(value) {
            this.selectedTab = value
        },
        handlerAddTimeRange() {
            this.item.timeRanges.push({
                from: '',
                to: ''
            })
        },
        handlerUpdateTimeRange(obj) {
            const value = obj.event.target.value
            if ('from' === obj.position) {
                this.item.timeRanges[obj.index].from = value
            } else if ('to' === obj.position) {
                this.item.timeRanges[obj.index].to = value
            }
        },
        handlerRemoveTimeRange(index) {
            this.item.timeRanges.splice(index, 1)
        },
        handlerAddEmployee(employeeId) {
            this.item.employeeId = ''
            const filtered = this.selectedEmployees.filter(employee => employee._id === employeeId)
            if (filtered.length === 0) {
                const addedEmployee = this.employees.filter(employee => employee._id === employeeId)
                this.selectedEmployees.push(addedEmployee[0])
            }
        },
        handlerRemoveEmployee(index) {
            this.selectedEmployees.splice(index, 1);
        },
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
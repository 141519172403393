<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content scroll-y-custom-2">
                            <form>
                                <div class="modal-body">
                                    <p class="color-gris-intermedio">
                                        <b class="color-gris">Registro de Horas Laborales</b>
                                    </p>
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link" :class="{'active': (selectedTab === 0) }" id="classification-tab" data-toggle="tab" href="#classification" role="tab" aria-controls="classification" :aria-selected="false" @click="setSelectedTab(0)">Clasificación</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" :class="{'active': (selectedTab === 1) }" id="times-tab" data-toggle="tab" href="#times" role="tab" aria-controls="times" :aria-selected="false" @click="setSelectedTab(1)">Tiempos</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" :class="{'active': (selectedTab === 2) }" id="employee-set-tab" data-toggle="tab" href="#employee-set" role="tab" aria-controls="employee-set" :aria-selected="false" @click="setSelectedTab(2)">Empleados</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content m-2" id="myTabContent">
                                        <div class="tab-pane fade" :class="[{'show': (selectedTab === 0) }, {'active': (selectedTab === 0) }]" id="classification" role="tabpanel" aria-labelledby="classification-tab">
                                            <ClassificationForm 
                                                :item="item"
                                                :cost-centers="costCenters" 
                                                :projects="projects"
                                            />
                                        </div>
                                        <div class="tab-pane fade" :class="[{'show': (selectedTab === 1) }, {'active': (selectedTab === 1) }]" id="times" role="tabpanel" aria-labelledby="times-tab">
                                            <TimesForm 
                                                :item="item"
                                                :addTimeRange="true"
                                                :period="period"
                                                @handler-add-time-range="handlerAddTimeRange"
                                                @handler-update-time-range="handlerUpdateTimeRange"
                                                @handler-remove-time-range="handlerRemoveTimeRange"
                                            />
                                        </div>
                                        <div class="tab-pane fade" :class="[{'show': (selectedTab === 2) }, {'active': (selectedTab === 2) }]" id="employee-set" role="tabpanel" aria-labelledby="employee-set-tab">
                                            <EmployeeGroupForm 
                                                :item="item"
                                                :employees="employees"
                                                :selected-employees="selectedEmployees"
                                                @handler-add-employee="handlerAddEmployee"
                                                @handler-remove-employee="handlerRemoveEmployee"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!isInsidePeriod()" class="modal-footer">
                                    <div class="row">
                                        <div class="col-sm-12 text-justify">
                                            <span class="text-danger"><b>Nota:</b> Está fuera de periodo, puede seguir registrando, sin embargo esto será auditado. La recomendación es cerrar este periodo y abrir uno nuevo.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onSave()" type="submit" class="btn btn-success btn-sm">Registrar</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
import ClassificationForm from './Classification.vue'
import TimesForm from './Times.vue'
import EmployeeGroupForm from './EmployeeGroup.vue'
export default {
    name: 'HoursRegistrySavedForm',
    components: {
        ClassificationForm,
        TimesForm,
        EmployeeGroupForm,
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        },
        costCenters: {
            type: Array,
            default() { return [] }
        },
        projects: {
            type: Array,
            default() { return [] }
        },
        employees: {
            type: Array,
            default() { return [] }
        },
        period: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedEmployees: [],
            selectedTab: 0,
        }
    },
    updated() {
        this.selectedEmployees = [];
        this.setSelectedTab(0)
    },
    methods: {
        onHide() {
            this.$emit('handler-visibility', false)
        },
        onSave() {
            this.item.employees = this.selectedEmployees;
            this.$emit('handler-save');
        },
        getCurrentDate() {
            const d = new Date();
            return d.toISOString().substr(0, 10)
        },
        isInsidePeriod() {
            const currentDate = new Date();
            const fromDate = new Date(`${this.period.from} 00:00`);
            const toDate = new Date(`${this.period.to} 23:59`);
            
            return (currentDate.getTime() >= fromDate.getTime() && currentDate.getTime() <= toDate.getTime())
        },
        setSelectedTab(value) {
            this.selectedTab = value
        },
        handlerAddTimeRange() {
            this.item.timeRanges.push({
                from: '',
                to: ''
            })
        },
        handlerUpdateTimeRange(obj) {
            const value = obj.event.target.value
            if ('from' === obj.position) {
                this.item.timeRanges[obj.index].from = value
            } else if ('to' === obj.position) {
                this.item.timeRanges[obj.index].to = value
            }
        },
        handlerRemoveTimeRange(index) {
            this.item.timeRanges.splice(index, 1)
        },
        handlerAddEmployee(employeeId) {
            this.item.employeeId = ''
            const filtered = this.selectedEmployees.filter(employee => employee._id === employeeId)
            if (filtered.length === 0) {
                const addedEmployee = this.employees.filter(employee => employee._id === employeeId)
                this.selectedEmployees.push(addedEmployee[0])
            }
        },
        handlerRemoveEmployee(index) {
            this.selectedEmployees.splice(index, 1);
        },
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
<template v-if="Object.entries(settings) > 0">
    <div class="row container-xl">
        <div class="col-sm-12 text-left pl-0">
            <p class="color-gris-intermedio" style="opacity: 0.75;">
                <i class="fas fa-cog" style="opacity: 0.75;"></i>
                <b><span class="color-gris"> Configuración de accesos</span></b>
            </p>
        </div>
    </div>

    <div class="modal-body" style="padding: 8px; height: 340px; overflow: hidden; overflow-y: auto; background: #e6e6e6;">
        <!-- ROW -->
        <div class="row justify-content-center">
            <!-- ITEM -->
            <div class="col-11 card mt-0 mb-2 ml-0 pl-0 pr-0" v-for="(m, i) in modules" :key="i">
                <div class="card-header p-2" :class="[{'bg-active': m.active}, {'bg-disable': !m.active}]"  style="font-size: 85%;">
                    <img :src="m.icon" :alt="m.name" width="40" style="padding-right: 10px;" :style="[{opacity: (!m.active) ? '0.2' : '1'}]">
                    <span style="font-size: 120%;"><b>{{ m.name }}</b></span>
                </div>

                <div class="card-body p-2">
                    <div v-for="(sm, j) in m.submodules" :key="j" class="mb-1">
                        <div class="row">
                            <div class="col-1">
                                <div class="custom-control custom-switch mt-0">
                                    <input v-model="settings[m.id][sm.id].active" class="custom-control-input" type="checkbox" value="" :id="'__' + sm.id">
                                    <label class="custom-control-label" :class="{'color-gris': !settings[m.id][sm.id].active}" :for="'__' + sm.id"></label>
                                </div>
                            </div>
                            <div class="col-7" :style="[{opacity: (!settings[m.id][sm.id].active) ? '0.45' : '1'}]">
                                <i :class="sm.iconClass"></i> {{sm.name}}
                            </div>
                            <div class="col-4">
                                <select v-show="settings[m.id][sm.id].active" v-model="settings[m.id][sm.id].permission" class="form-control form-control-sm" required>
                                    <option v-if="sm.id !== 'hoursRegistry'" value='READ'>Sólo Lectura</option>
                                    <option v-if="sm.id !== 'hoursRegistry'" value='WRITE'>Lectura y escritura</option>
                                    <option v-if="sm.id === 'hoursRegistry'" value='PARTICULAR_READ'>Lectura Particular</option>
                                    <option v-if="sm.id === 'hoursRegistry'" value='GENERAL_READ'>Lectura General</option>
                                    <option v-if="sm.id === 'hoursRegistry'" value='PR_WRITE'>Lectura P. + Escritura</option>
                                    <option v-if="sm.id === 'hoursRegistry'" value='GR_WRITE'>Lectura G. + Escritura</option>
                                </select>
                            </div>
                        </div>
                        <hr class="hr-customized"/>
                    </div>                    
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'AccessSettingsForm',
    props: {
        settings: Object
    },
    watch: {
        'settings.systemSetting': {
            handler: function(obj) {
                this.modules[0].active = this.isActive(obj)
            },
            deep: true
        },
        'settings.collaborators': {
            handler: function(obj) {
                this.modules[1].active = this.isActive(obj)
            },
            deep: true
        },
        'settings.generalParameters': {
            handler: function(obj) {
                this.modules[2].active = this.isActive(obj)
            },
            deep: true
        },
        'settings.gestion': {
            handler: function(obj) {
                this.modules[3].active = this.isActive(obj)
            },
            deep: true
        },
        'settings.reports': {
            handler: function(obj) {
                this.modules[4].active = this.isActive(obj)
            },
            deep: true
        }
    },
    mounted() {
        this.initialize()
    },
    data() {
        return {
            modules: [
                {
                    id: 'systemSetting',
                    name: 'Configuración de sistema',
                    icon: this.getImageUrl('settings_.png'),
                    active: false,
                    submodules: [
                        {
                            id: 'roles',
                            name: 'Roles',
                            iconClass: 'fas fa-user-tag'
                        },
                        {
                            id: 'userAccounts',
                            name: 'Cuentas de usuario',
                            iconClass: 'fas fa-users-cog'
                        },
                        {
                            id: 'integration',
                            name: 'Integraciones',
                            iconClass: 'fas fa-link'
                        }
                    ]
                },
                {
                    id: 'collaborators',
                    name: 'Colaboradores',
                    icon: this.getImageUrl('employees_.png'),
                    active: false,
                    submodules: [
                        {
                            id: 'costCenters',
                            name: 'Centros de costos',
                            iconClass: 'fas fa-hand-holding-usd'
                        },
                        {
                            id: 'projects',
                            name: 'Proyectos',
                            iconClass: 'fas fa-lightbulb'
                        },
                        {
                            id: 'areas',
                            name: 'Áreas',
                            iconClass: 'fas fa-network-wired'
                        },
                        {
                            id: 'groups',
                            name: 'Grupos',
                            iconClass: 'fas fa-users'
                        },
                        {
                            id: 'positions',
                            name: 'Cargos',
                            iconClass: 'fas fa-briefcase'
                        },
                        {
                            id: 'employees',
                            name: 'Empleados',
                            iconClass: 'fas fa-hard-hat'
                        }
                    ]
                },
                {
                    id: 'generalParameters',
                    name: 'Parámetros generales',
                    icon: this.getImageUrl('general-parameters_.png'),
                    active: false,
                    submodules: [
                        {
                            id: 'hoursType',
                            name: 'Tipos de horas',
                            iconClass: 'far fa-clock'
                        },
                        {
                            id: 'newsTypes',
                            name: 'Tipos de novedades',
                            iconClass: 'fas fa-star-of-life'
                        },
                        {
                            id: 'rules',
                            name: 'Reglas',
                            iconClass: 'fas fa-cogs'
                        },
                        {
                            id: 'holidays',
                            name: 'Festivos',
                            iconClass: 'far fa-calendar-alt'
                        },
                        {
                            id: 'shits',
                            name: 'Turnos',
                            iconClass: 'fas fa-hourglass-end'
                        },
                        {
                            id: 'shitsScheduling',
                            name: 'Programación',
                            iconClass: 'fas fa-user-clock'
                        },
                        {
                            id: 'approvalSetup',
                            name: 'Configuración de aprobaciones',
                            iconClass: 'fas fa-check-double'
                        }
                    ]
                },
                {
                    id: 'gestion',
                    name: 'Gestión',
                    icon: this.getImageUrl('gestion_.png'),
                    active: false,
                    submodules: [
                        {
                            id: 'periods',
                            name: 'Períodos',
                            iconClass: 'fas fa-calendar-week'
                        },
                        {
                            id: 'newsRegistry',
                            name: 'Registro de novedades',
                            iconClass: 'fas fa-file-alt'
                        },
                        {
                            id: 'hoursRegistry',
                            name: 'Registro de horas',
                            iconClass: 'fas fa-business-time'
                        },
                        {
                            id: 'timeSettlement',
                            name: 'Liquidación de tiempos',
                            iconClass: 'fas fa-business-time'
                        },
                        {
                            id: 'approvals',
                            name: 'Aprobaciones',
                            iconClass: 'fas fa-check-square'
                        }
                    ]
                },
                {
                    id: 'reports',
                    name: 'Reportes',
                    icon: this.getImageUrl('reports_.png'),
                    active: false,
                    submodules: [
                        {
                            id: 'markups',
                            name: 'Reporte de marcaciones',
                            iconClass: 'fas fa-poll'
                        },
                        {
                            id: 'news',
                            name: 'Reporte de novedades',
                            iconClass: 'fas fa-poll'
                        },
                        {
                            id: 'detailOfHoursWorked',
                            name: 'Reporte detallado de horas trabajadas',
                            iconClass: 'fas fa-poll'
                        },
                        {
                            id: 'summaryOfHoursWorked',
                            name: 'Reporte resumido de horas trabajadas',
                            iconClass: 'fas fa-poll'
                        },
                        {
                            id: 'lateArrivals',
                            name: 'Reporte de entradas tardes',
                            iconClass: 'fas fa-poll'
                        },
                        {
                            id: 'earlyDepartures',
                            name: 'Reporte de salidas anticipadas',
                            iconClass: 'fas fa-poll'
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        initialize() {
            this.modules[0].active = this.isActive(this.settings['systemSetting'])
            this.modules[1].active = this.isActive(this.settings['collaborators'])
            this.modules[2].active = this.isActive(this.settings['generalParameters'])
            this.modules[3].active = this.isActive(this.settings['gestion'])
            this.modules[4].active = this.isActive(this.settings['reports'])
        },
        isActive(obj) {
            let array = Object.entries(obj)
            let result = array.some((a) => {
                return a.some(e => e.active === true)
            })

            return result
        },
        getImageUrl(imageName) {
            let images = require.context('../../../../../assets/menu/', false, /\.png$/);
            return images('./' + imageName);
        },
    }
}
</script>

<style>

</style>
<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.systemSetting && $root.data.accessList.systemSetting.userAccounts && $root.data.accessList.systemSetting.userAccounts.active))">

      <div class="card shadow" style="margin-top: 85px!important;">
        
          <Headboard 
            :title="'Administración de Cuentas de Usuario'"
            :icon-reference="'fas fa-users-cog'"
            :loading="sending"
            :applied-filters="appliedFilters"
            :minimalist="$root.data.minimalist"
            :create-action-name="'Nueva Cuenta de Usuario'"
            :permission="$root.data.accessList.systemSetting.userAccounts.permission"
            @handler-refresh="handlerRefresh"
            @handler-show-filter="handlerShowFilter"
            @handler-show-form-save="handlerShowSavedForm"
          />

          <div class="card-body container-pager-grid">
              <Pager 
                :pagination="pagination"
                :records-count="records.length"
                @handler-previous-page="handlerPreviousPage"
                @handler-next-page="handlerNextPage"
              />

              <CustomGrid
                :visible="!sending"
                :condensed="true"
                :titles="grid.titles"
                :mapping="grid.mapping"
                :records="records"
                :pagination="pagination"
                :permission="$root.data.accessList.systemSetting.userAccounts.permission"
                @handler-show-form-save="handlerShowSavedForm"
                @handler-show-status-change="handlerShowStatusChange"
              />              
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <FilteredOutForm :visible="showFilters" :roles="roles" :filters="filters" @handler-show-filter="handlerShowFilter"  @handler-reset-filter="handlerResetFilter" @handler-apply-filter="handlerApplyFilter" />
      <SavedForm :visible="showSavedForm" :roles="roles" :item="documentObj" @handler-visibility="handlerHideSavedForm" @handler-save="handlerSave" />
      <StatusChangeForm :visible="showStatusChange" :item="documentObj" @handler-hide-status-change="handlerHideStatusChange" @handler-status-change="handlerStatusChange" />
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import Pager from '../../../Widgets/Pager.vue'
import CustomGrid from '../../../Widgets/CustomGrid.vue'
import FilteredOutForm from './Forms/FilteredOutForm.vue'
import SavedForm from './Forms/SavedForm.vue'
import StatusChangeForm from './Forms/StatusChangeForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
export default {
    name: 'UserAccounts',
    components: {
      Headboard,
      Pager,
      CustomGrid,
      FilteredOutForm,
      SavedForm,
      StatusChangeForm,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        roles: [],
        records: [],
        documentObj: {
          id: '',
          name: '',
          surname: '',
          email: '',
          username: '',
          password: '',
          roleId: '',
          status: ''
        },
        filters: {
          name: '',
          surname: '',
          email: '',
          username: '',
          roleId: '',
          status: ''
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        hide: false,
        sending: false,
        appliedFilters: false,
        showFilters: false,
        showSavedForm: false,
        showStatusChange: false,
        pagination: {
          currentStart: 0,
          currentEnd: 0,
          total: 0,
          currentPage: 1,
          totalPage: 1,
          limit: 15
        },
        grid: {
          titles: [{name: 'Nombre'}, {name: 'Email'}, {name: 'Usuario'}, {name: 'Rol Asociado'},  {name: 'Estado'}, {name: 'Acciones', center: true}],
          mapping: [
            {
              ref: 'fullname'
            },
            {
              ref: 'email'
            },
            {
              ref: 'username'
            },
            { 
              mode: 'HIGHLIGHTED',
              ref: 'role',
              class: 'badge badge-info'
            },
            { 
              mode: 'OPTIONS',
              ref: 'status',
              class: {
                ACTIVO: 'badge badge-primary',
                INACTIVO: 'badge badge-secondary'
              }
            },
            {
              mode: 'ACTIONS',
              center: false,
              actions: [
                {
                  type: 'STATUS_CHANGE',
                  tooltip: {
                    ACTIVO: 'Deshabilitar cuenta de usuario',
                    INACTIVO: 'Habilitar cuenta de usuario'
                  },
                  class: {
                    ACTIVO: 'fas fa-user-alt color-verde-fuerte',
                    INACTIVO: 'fas fa-user-alt-slash color-rojo-intermedio'
                  },
                  handler: 'handler-show-status-change'
                },
                {
                  type: 'EDIT',
                  tooltip: 'Editar',
                  handler: 'handler-show-form-save'
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.sending = false
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.pagination.currentPage = callback.response.currentPage
          this.pagination.totalPages = callback.response.totalPages
          this.pagination.currentStart = ((this.pagination.limit * (this.pagination.currentPage - 1)) + 1)
          this.pagination.currentEnd = ((this.pagination.limit * (this.pagination.currentPage - 1)) + callback.response.records.length)
          this.pagination.total = callback.response.totalRecords
          this.records = callback.response.records
          this.roles = callback.response.roles
        } else {
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.pagination.currentPage = 1
        this.consultBuilder()
      },
      handlerPreviousPage() {
        this.pagination.currentPage -= 1
        this.consultBuilder()
      },
      handlerNextPage() {
        this.pagination.currentPage += 1
        this.consultBuilder()
      },
      handlerShowFilter(v) {
        this.showFilters = v
      },
      handlerResetFilter() {
        this.filters = {
          name: '',
          surname: '',
          email: '',
          username: '',
          roleId: '',
          status: ''
        }
        this.appliedFilters = false
        this.showFilters = false
        this.consultBuilder()
      },
      handlerApplyFilter() {
        this.appliedFilters = true
        this.showFilters = false
        this.consultBuilder()
      },
      handlerHideSavedForm() {
        this.showSavedForm = false
      },
      handlerShowSavedForm(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          name: (item && item.name) ? item.name : '',
          surname: (item && item.surname) ? item.surname : '',
          email: (item && item.email) ? item.email : '',
          username: (item && item.username) ? item.username : '',
          password: '',
          roleId: (item && item.roleId) ? item.roleId : '',
          status: (item && item.status) ? item.status : ''
        }
        this.showSavedForm = true
      },
      handlerSave() {
        this.handlerHideSavedForm()
        this.saveBuilder(this.documentObj)
      },
      handlerShowStatusChange(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          name: (item && item.fullname) ? item.fullname : '',
          status: (item && item.status) ? item.status : ''
        }
        this.showStatusChange = true
      },
      handlerHideStatusChange() {
        this.showStatusChange = false
      },
      handlerStatusChange() {
        this.handlerHideStatusChange()
        this.statusChangeBuilder(this.documentObj)
      },

      consultBuilder() {
        this.hide = true
        this.httpConsumerData = {
          endpoint: '/account/manager/list',
          method: 'post',
          withConfirmation: false,
          requestBody: {
            name: this.filters.name,
            surname: this.filters.surname,
            email: this.filters.email,
            username: this.filters.username,
            roleId: this.filters.roleId,
            status: this.filters.status,
            page: this.pagination.currentPage,
            limit: this.pagination.limit
          }
        }
        this.sending = true
      },

      saveBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/account/manager/',
          method: (item && item.id) ? 'put' : 'post',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            name: item.name,
            surname: item.surname,
            email: item.email,
            username: item.username,
            password: item.password,
            roleId: item.roleId,
            status: item.status
          }
        }

        this.sending = true
      },

      statusChangeBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/account/manager/statusChange',
          method: 'put',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            status: item.status
          }
        }

        this.sending = true
      }

    }
}
</script>

<style>

</style>
<template>
    <div>
        <ul class="navbar-nav bg-gradient-primary sidebar-customized" id="accordionSidebar">
            <!-- Sidebar - Brand -->
            <div id="ariak-sidebar">
                <a class="sidebar-brand d-flex align-items-center justify-content-center" style="margin-top: 10px; margin-bottom: 15px;">
                    <div class="sidebar-brand-icon" style="text-align: center; margin-top: 10px;">
                        <div class="row">
                            <div class="cols-12">
                                <img src="../../../assets/logo__main.png" alt="" style="width: 50%;">
                            </div>
                        </div>
                    </div>
                </a>
                <hr class="mt-0 mb-1" style="background: #99c1ee; opacity: 0.2;">
            </div>
            <!-- End Sidebar - Brand -->

            <!-- Items Menu -->
            <div id="ariak-items-menu">
                <div v-for="(group, index) in groupers" :key="index">
                    <MenuGrouper :index="index" :title="group.title" :icon="group.icon" :is-expandable="group.isExpandable" :is-active="group.isActive" @handler-expandable="handlerExpandable" />
                    <MenuItem v-for="item in group.items" :key="item.id" :id="item.id" :title="item.title" :link="item.link" :is-visible="group.isExpandable" :is-active="item.isActive" :is-selected="((activeValue === item.id))" @handler-mark-as-active="handlerMarkAsActive" />
                </div>
            </div>
            <!-- End Items Menu -->

        </ul>
    </div>
</template>

<script>
import MenuGrouper from './Parts/Grouper.vue'
import MenuItem from './Parts/Item.vue'
export default {
    name: 'Menu',
    components: {
        MenuGrouper,
        MenuItem
    },
    props: {
        accessSettings: {
            type: Object,
            default() { return {} }
        }
    },
    data() {
        return {
            activeValue: 0,
            groupers: [
                {
                    title: 'CONFIGURACIÓN DE SISTEMA',
                    icon: this.getImageUrl('settings_.png'),
                    isActive: 
                        (this.accessSettings && this.accessSettings.systemSetting && 
                            (
                                (this.accessSettings.systemSetting.roles && this.accessSettings.systemSetting.roles.active) ||
                                (this.accessSettings.systemSetting.userAccounts && this.accessSettings.systemSetting.userAccounts.active) ||
                                (this.accessSettings.systemSetting.integration && this.accessSettings.systemSetting.integration.active)
                            )
                        ),
                    isExpandable: false,
                    items: [
                        {
                            id: 1,
                            title: 'ROLES',
                            link: '/roles',
                            isActive: (this.accessSettings && this.accessSettings.systemSetting && this.accessSettings.systemSetting.roles && this.accessSettings.systemSetting.roles.active)
                        },
                        {
                            id: 2,
                            title: 'CUENTAS DE USUARIO',
                            link: '/userAccounts',
                            isActive: (this.accessSettings && this.accessSettings.systemSetting && this.accessSettings.systemSetting.userAccounts && this.accessSettings.systemSetting.userAccounts.active)
                        },
                        {
                            id: 3,
                            title: 'INTEGRACIÓN',
                            link: '/integrations',
                            isActive: (this.accessSettings && this.accessSettings.systemSetting && this.accessSettings.systemSetting.integration && this.accessSettings.systemSetting.integration.active)
                        }
                    ]
                },
                {
                    title: 'COLABORADORES',
                    icon: this.getImageUrl('employees_.png'),
                    isActive: 
                        (this.accessSettings && this.accessSettings.collaborators && 
                            (
                                (this.accessSettings.collaborators.costCenters && this.accessSettings.collaborators.costCenters.active) ||
                                (this.accessSettings.collaborators.projects && this.accessSettings.collaborators.projects.active) ||
                                (this.accessSettings.collaborators.areas && this.accessSettings.collaborators.areas.active) ||
                                (this.accessSettings.collaborators.groups && this.accessSettings.collaborators.groups.active) ||
                                (this.accessSettings.collaborators.positions && this.accessSettings.collaborators.positions.active) ||
                                (this.accessSettings.collaborators.employees && this.accessSettings.collaborators.employees.active)
                            )
                        ),
                    isExpandable: false,
                    items: [
                        {
                            id: 4,
                            title: 'CENTROS DE COSTOS',
                            link: '/costCenters',
                            isActive: (this.accessSettings && this.accessSettings.collaborators && this.accessSettings.collaborators.costCenters && this.accessSettings.collaborators.costCenters.active)
                        },
                        {
                            id: 5,
                            title: 'PROYECTOS',
                            link: '/projects',
                            isActive: (this.accessSettings && this.accessSettings.collaborators && this.accessSettings.collaborators.projects && this.accessSettings.collaborators.projects.active)
                        },
                        {
                            id: 6,
                            title: 'ÁREAS',
                            link: '/areas',
                            isActive: (this.accessSettings && this.accessSettings.collaborators && this.accessSettings.collaborators.areas && this.accessSettings.collaborators.areas.active)
                        },
                        {
                            id: 7,
                            title: 'GRUPOS',
                            link: '/groups',
                            isActive: (this.accessSettings && this.accessSettings.collaborators && this.accessSettings.collaborators.groups && this.accessSettings.collaborators.groups.active)
                        },
                        {
                            id: 8,
                            title: 'CARGOS',
                            link: '/positions',
                            isActive: (this.accessSettings && this.accessSettings.collaborators && this.accessSettings.collaborators.positions && this.accessSettings.collaborators.positions.active)
                        },
                        {
                            id: 9,
                            title: 'EMPLEADOS',
                            link: '/employees',
                            isActive: (this.accessSettings && this.accessSettings.collaborators && this.accessSettings.collaborators.employees && this.accessSettings.collaborators.employees.active)
                        }
                    ]
                },
                {
                    title: 'PARÁMETROS GENERALES',
                    icon: this.getImageUrl('general-parameters_.png'),
                    isActive: (this.accessSettings && this.accessSettings.generalParameters && 
                            (
                                (this.accessSettings.generalParameters.hoursType && this.accessSettings.generalParameters.hoursType.active) ||
                                (this.accessSettings.generalParameters.newsTypes && this.accessSettings.generalParameters.newsTypes.active) ||
                                (this.accessSettings.generalParameters.rules && this.accessSettings.generalParameters.rules.active) ||
                                (this.accessSettings.generalParameters.holidays && this.accessSettings.generalParameters.holidays.active) ||
                                (this.accessSettings.generalParameters.shits && this.accessSettings.generalParameters.shits.active) ||
                                (this.accessSettings.generalParameters.shitsScheduling && this.accessSettings.generalParameters.shitsScheduling.active) ||
                                (this.accessSettings.generalParameters.approvalSetup && this.accessSettings.generalParameters.approvalSetup.active)
                            )
                        ),
                    isExpandable: false,
                    items: [
                        {
                            id: 10,
                            title: 'TIPOS DE HORAS',
                            link: '/hourTypes',
                            isActive: (this.accessSettings && this.accessSettings.generalParameters && this.accessSettings.generalParameters.hoursType && this.accessSettings.generalParameters.hoursType.active)
                        },
                        {
                            id: 11,
                            title: 'TIPOS DE NOVEDADES',
                            link: '/newsTypes',
                            isActive: (this.accessSettings && this.accessSettings.generalParameters && this.accessSettings.generalParameters.newsTypes && this.accessSettings.generalParameters.newsTypes.active)
                        },
                        {
                            id: 12,
                            title: 'PARAMETRIZACIÓN',
                            link: '/rules',
                            isActive: (this.accessSettings && this.accessSettings.generalParameters && this.accessSettings.generalParameters.rules && this.accessSettings.generalParameters.rules.active)
                        },
                        {
                            id: 13,
                            title: 'FESTIVOS',
                            link: '/holidays',
                            isActive: (this.accessSettings && this.accessSettings.generalParameters && this.accessSettings.generalParameters.holidays && this.accessSettings.generalParameters.holidays.active)
                        },
                        {
                            id: 14,
                            title: 'TURNOS',
                            link: '/shifts',
                            isActive: (this.accessSettings && this.accessSettings.generalParameters && this.accessSettings.generalParameters.shits && this.accessSettings.generalParameters.shits.active)
                        },
                        {
                            id: 15,
                            title: 'PROGRAMACIÓN',
                            link: '/shiftScheduling',
                            isActive: (this.accessSettings && this.accessSettings.generalParameters && this.accessSettings.generalParameters.shitsScheduling && this.accessSettings.generalParameters.shitsScheduling.active)
                        },
                        {
                            id: 16,
                            title: 'CONFIGURACIÓN DE APROBACIONES',
                            link: '/approvalSetup',
                            isActive: (this.accessSettings && this.accessSettings.generalParameters && this.accessSettings.generalParameters.approvalSetup && this.accessSettings.generalParameters.approvalSetup.active)
                        }
                    ]
                },
                {
                    title: 'GESTIÓN',
                    icon: this.getImageUrl('gestion_.png'),
                    isActive: (this.accessSettings && this.accessSettings.gestion && 
                            (
                                (this.accessSettings.gestion.newsRegistry && this.accessSettings.gestion.newsRegistry.active) ||
                                (this.accessSettings.gestion.hoursRegistry && this.accessSettings.gestion.hoursRegistry.active) ||
                                (this.accessSettings.gestion.timeSettlement && this.accessSettings.gestion.timeSettlement.active) ||
                                (this.accessSettings.gestion.approvals && this.accessSettings.gestion.approvals.active)
                            )
                        ),
                    isExpandable: false,
                    items: [
                        {
                            id: 24,
                            title: 'PERÍODOS',
                            link: '/periods',
                            isActive: (this.accessSettings && this.accessSettings.gestion && this.accessSettings.gestion.periods && this.accessSettings.gestion.periods.active)
                        },
                        {
                            id: 17,
                            title: 'REGISTRO DE NOVEDADES',
                            link: '/newsRegistry',
                            isActive: (this.accessSettings && this.accessSettings.gestion && this.accessSettings.gestion.newsRegistry && this.accessSettings.gestion.newsRegistry.active)
                        },
                        {
                            id: 18,
                            title: 'REGISTRO DE HORAS',
                            link: '/hoursRegistry',
                            isActive: (this.accessSettings && this.accessSettings.gestion && this.accessSettings.gestion.hoursRegistry && this.accessSettings.gestion.hoursRegistry.active)
                        },
                        {
                            id: 19,
                            title: 'LIQUIDADACIÓN DE TIEMPOS',
                            link: '/timeSettlement',
                            isActive: (this.accessSettings && this.accessSettings.gestion && this.accessSettings.gestion.timeSettlement && this.accessSettings.gestion.timeSettlement.active)
                        },
                        {
                            id: 20,
                            title: 'APROBACIONES',
                            link: '/approvals',
                            isActive: (this.accessSettings && this.accessSettings.gestion && this.accessSettings.gestion.approvals && this.accessSettings.gestion.approvals.active)
                        }
                    ]
                },
                {
                    title: 'REPORTES',
                    icon: this.getImageUrl('reports_.png'),
                    isActive: (this.accessSettings && this.accessSettings.reports && 
                            (
                                (this.accessSettings.reports.markups && this.accessSettings.reports.markups.active) ||
                                (this.accessSettings.reports.news && this.accessSettings.reports.news.active) ||
                                (this.accessSettings.reports.detailOfHoursWorked && this.accessSettings.reports.detailOfHoursWorked.active) ||
                                (this.accessSettings.reports.earlyDepartures && this.accessSettings.reports.earlyDepartures.active) ||
                                (this.accessSettings.reports.lateArrivals && this.accessSettings.reports.lateArrivals.active) ||
                                (this.accessSettings.reports.earlyDepartures && this.accessSettings.reports.earlyDepartures.active)
                            )
                        ),
                    isExpandable: false,
                    items: [
                        {
                            id: 21,
                            title: 'REPORTE DE MARCACIONES',
                            link: '/markups',
                            isActive: (this.accessSettings && this.accessSettings.reports && this.accessSettings.reports.markups && this.accessSettings.reports.markups.active)
                        },
                        {
                            id: 22,
                            title: 'REPORTE DE NOVEDADES',
                            link: '/news',
                            isActive: (this.accessSettings && this.accessSettings.reports && this.accessSettings.reports.news && this.accessSettings.reports.news.active)
                        },
                        {
                            id: 23,
                            title: 'REPORTE DETALLADO DE HORAS TRABAJADAS',
                            link: '/detailOfHoursWorked',
                            isActive: (this.accessSettings && this.accessSettings.reports && this.accessSettings.reports.detailOfHoursWorked && this.accessSettings.reports.detailOfHoursWorked.active)
                        },
                        {
                            id: 24,
                            title: 'REPORTE RESUMIDO DE HORAS TRABAJADAS',
                            link: '/summaryOfHoursWorked',
                            isActive: (this.accessSettings && this.accessSettings.reports && this.accessSettings.reports.summaryOfHoursWorked && this.accessSettings.reports.summaryOfHoursWorked.active)
                        },
                        {
                            id: 25,
                            title: 'REPORTE DE ENTRADAS TARDES',
                            link: '/lateArrivals',
                            isActive: (this.accessSettings && this.accessSettings.reports && this.accessSettings.reports.lateArrivals && this.accessSettings.reports.lateArrivals.active)
                        },
                        {
                            id: 26,
                            title: 'REPORTE DE SALIDAS ANTICIPADAS',
                            link: '/earlyDepartures',
                            isActive: (this.accessSettings && this.accessSettings.reports && this.accessSettings.reports.earlyDepartures && this.accessSettings.reports.earlyDepartures.active)
                        }
                    ]
                }
            ]
        }
    },
    mounted() {
        this.pathLoad()
    },
    methods: {
        pathLoad() {
            const currentRoute = this.$route.path
            switch(currentRoute) {
                case '/':
                    this.activeValue = 0
                    break
                case '/roles':
                    this.activeValue = 1
                    break
                case '/userAccounts':
                    this.activeValue = 2
                    break
                case '/integrations':
                    this.activeValue = 3
                    break
                case '/areas':
                    this.activeValue = 4
                    break
                case '/groups':
                    this.activeValue = 5
                    break
                case '/positions':
                    this.activeValue = 6
                    break
                case '/workers':
                    this.activeValue = 7
                    break
                case '/hourTypes':
                    this.activeValue = 8
                    break
                case '/newsTypes':
                    this.activeValue = 9
                    break
                case '/rules':
                    this.activeValue = 10
                    break
                case '/holidays':
                    this.activeValue = 11
                    break
                case '/shifts':
                    this.activeValue = 12
                    break
                case '/shiftScheduling':
                    this.activeValue = 13
                    break
                case '/approvalSetup':
                    this.activeValue = 14
                    break
                case '/newsRegistry':
                    this.activeValue = 15
                    break
                case '/timeSettlement':
                    this.activeValue = 16
                    break
                case '/approvals':
                    this.activeValue = 17
                    break
                case '/markups':
                    this.activeValue = 18
                    break
                case '/news':
                    this.activeValue = 19
                    break
                case '/detailOfHoursWorked':
                    this.activeValue = 20
                    break
                case '/summaryOfHoursWorked':
                    this.activeValue = 21
                    break
                case '/lateArrivals':
                    this.activeValue = 22
                    break
                case '/earlyDepartures':
                    this.activeValue = 23
                    break
                case '/periods':
                    this.activeValue = 24
                    break
            }
        },
        handlerMarkAsActive(value) {
            this.$root.restartTimer()
            this.activeValue = value
        },
        handlerExpandable(index) {
            let obj = Object.assign({}, this.groupers[index])
            obj.isExpandable = !obj.isExpandable
            this.groupers[index] = obj
        },
        getImageUrl(imageName) {
            let images = require.context('../../../assets/menu/', false, /\.png$/);
            return images('./' + imageName);
        },
    }
}
</script>

<style scoped>
    #ariak-sidebar {
        display: block;
    }
    #ariak-items-menu {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }
    .sidebar-customized {
        position: fixed;
        width: 20%;
        height: 100%;
    }

    .font-icon-item {
        font-size: 14px;
    }

    @media screen and (min-width: 795px) and (max-width: 1030px) {
        .font-icon-item {
            font-size: 12px;
        }

        .font-item {
            font-size: 12px;
        }

        .section-menu {
            margin-left: 8px; 
            font-size: 0.6rem;
        }
    }

    @media screen and (max-width: 793px) {
        .font-icon-item {
            margin-left: 5px;
            font-size: 25px;
        }

        .font-item {
            font-size: 0px;
        }

        .section-menu {
            margin-left: 10px; 
            font-size: 0.5rem;
        }
    }

    @media screen and (max-width: 500px) {
        .font-icon-item {
            margin-left: 0px; 
            font-size: 20px;
        }

        .font-item {
            font-size: 0px;
        }

        .section-menu {
            margin-left: 4px;
            font-size: 0.5rem;
        }
    }
</style>
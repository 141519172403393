<template>
  <div class="table-responsive-xl table-customized-scrolleable">
      <table class="table table-hover" id="dataTable" cellspacing="0" style="margin-bottom: 0; width: 99%; font-size: 78%;">
          <thead>
            <tr>
                <th class="py-1" style="display: table-cell; width: 30px;">No.</th>
                <th class="py-1" v-for="(t, i) in titles" :key="i" :class="{'text-center': t.center}">{{t.name}}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(item, index) in records" :key="item._id">
                  <td class="color-gris-intermedio custom-padding-top-botton">
                      <div class="custom-width-tiny color-gris-intermedio">{{( ((pagination.currentPage - 1) * pagination.limit) + index + 1)}}</div>
                  </td>
                  <td class="custom-padding-top-botton" v-for="m in mapping" :key="m.ref" :class="[{'custom-width-md': condensed}, {'text-center': m.center}]">
                      <div v-if="m.mode === 'ACTIONS'" class="row justify-content-center">
                          <div class="col-sm-0" v-for="(action, key) in m.actions" :key="key">
                              <a :title="getTooltip(item, action)" style="cursor: pointer;" @click="onEmit(action.handler, item)">
                                  <i v-if="(permission === 'WRITE' || permission === 'GR_WRITE') && action.type === 'EDIT'" class="fas fa-edit color-naranja-intermedio"></i>
                                  <i v-else-if="action.type === 'DETAIL'" class="fas fa-eye color-azul-intermedio"></i>
                                  <i v-else-if="(permission === 'WRITE' || permission === 'GR_WRITE') && action.type === 'STATUS_CHANGE'" :class="action.class[item['status']]"></i>
                                  <i v-else-if="(permission === 'WRITE' || permission === 'GR_WRITE') && action.type === 'DELETE'" class="fas fa-trash-alt color-rojo-intermedio"></i>
                                  <i v-else-if="(permission === 'WRITE' || permission === 'GR_WRITE') && action.type === 'CONFIG'" class="fas fa-cog color-gris-intermedio"></i>
                                  <i v-else-if="(permission === 'WRITE' || permission === 'GR_WRITE') && action.type === 'EXIT' && item['status'] === 'CURRENT' && item['transformedStatus'] === 'ABIERTO'" class="fas fa-times-circle color-rojo-intermedio"></i>
                                  <i v-else-if="(permission === 'WRITE' || permission === 'GR_WRITE') && action.type === 'VIEW' && item['status'] === 'CLOSED' && item['transformedStatus'] === 'CERRADO'" class="far fa-eye color-azul-intermedio"></i>
                                  <i v-else-if="(permission === 'WRITE' || permission === 'GR_WRITE') && action.type === 'NOT_VIEW' && item['status'] === 'CLOSED' && item['transformedStatus'] === 'LECTURA'" class="fas fa-eye-slash color-rojo-intermedio"></i>
                              </a>
                          </div>
                      </div>
                      <div v-else-if="m.mode === 'MULTI_FIELDS'">
                          <div class="custom-ellipsis-lg" v-for="r in m.refs" :key="r.name" :title="r.title + ': ' + item[m.ref][r.name]">
                              <b v-if="r.iconClass"><i :class="r.iconClass"></i></b>
                              <b v-else class="badge badge-light">{{r.title}}:</b>
                              <span v-if="r.type === 'OPTIONS'" :class="r.class[item[m.ref][r.name]]">{{ item[m.ref][r.name] }}</span>
                              <span v-else>{{ item[m.ref][r.name] }}</span>
                          </div>
                          <div class="custom-ellipsis-lg d-inline mr-2" v-for="r in m.inlineRefs" :key="r.name" :title="r.title + ': ' + item[m.ref][r.name]">
                              <b v-if="r.iconClass"><i :class="r.iconClass"></i></b>
                              <b v-else class="badge badge-light">{{r.title}}:</b>
                              <span v-if="r.type === 'OPTIONS'" :class="r.class[item[m.ref][r.name]]">{{ item[m.ref][r.name] }}</span>
                              <span v-else>{{ item[m.ref][r.name] }}</span>
                          </div>
                      </div>
                      <div v-else-if="m.mode === 'ONLY_ACTION'">
                          <div class="row justify-content-center">
                              <div v-if="permission === 'WRITE'" class="col-sm-4">
                                  <a :title="m.tooltip[item[m.ref]]" style="cursor: pointer;" @click="onEmit(m.handler, item)">
                                    <i :class="m.class[item[m.ref]]"></i>
                                  </a>
                              </div>
                              <div v-else class="col-sm-4 pt-1" :class="m.class[item[m.ref]]" :title="m.tooltip[item[m.ref]]"></div>
                          </div>
                      </div>
                      <div v-else-if="m.mode === 'OPTIONS'" :class="m.class[item[m.ref]]" :title="item[m.ref]">{{item[m.ref]}}</div>
                      <div v-else-if="m.mode === 'HIGHLIGHTED'" :class="m.class" :title="item[m.ref]">{{item[m.ref]}}</div>
                      <div v-else-if="m.mode === 'IMAGE'">
                          <img :src="item[m.ref]" :style="m.style.option" :width="m.style.width" :height="m.style.width" />
                      </div>
                      <div v-else class="custom-ellipsis-md" :title="item[m.ref]">
                          <span :class="m.class">{{ item[m.ref] }}</span>
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>      
  </div>
  <Loading v-if="!visible" />
</template>

<script>
import Loading from './Loading.vue'
export default {
    name: 'CustomGrid',
    components: {
        Loading
    },
    props: {
        visible: {
            type: Boolean,
            default: true
        },
        condensed: {
            type: Boolean,
            default: false
        },
        titles: {
            type: Array,
            default() { return [] }
        },
        mapping: {
            type: Array,
            default() { return [] }
        },
        records: {
            type: Array,
            default() { return [] }
        },
        pagination: {
            type: Object,
            default() {
                return {
                    currentPage: 1,
                    limit: 0
                }
            }
        },
        permission: {
            type: String,
            default: 'READ'
        }
    },
    methods: {
        getTooltip(item, action) {
            if (action.type === 'STATUS_CHANGE') {
                let tooltipRef = item['status']
                return action.tooltip[tooltipRef]
            }
            return action.tooltip
        },
        onEmit(handlerName, obj = null) {
            this.$emit(handlerName, obj)
        }
    }
}
</script>

<style scoped>
    .custom-padding-top-botton {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }
    .custom-width-tiny {
        width: 30px;
    }
    .custom-width-sm {
        width: 100px;
    }
    .custom-width-md {
        width: 140px;
    }
    .custom-width-lg {
        width: 200px;
    }
    .custom-ellipsis-sm {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .custom-ellipsis-md {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .custom-ellipsis-lg {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media screen and (max-width: 609px) {
        .table-customized-scrolleable {
            top: 225px;
        }
    }
</style>>
<template>
    <div></div>    
</template>

<script>
export default {
    name: 'ApiInteractionNotifier',
    props: {
        endpoint: {
            type: String,
            required: true
        },
        httpMethod: {
            type: String,
            default: 'get',
            required: true
        },
        requestBody: {
            type: Object,
            default() {
                return {}
            },
            required: true
        },
        withConfirmation: {
            type: Boolean,
            default: false
        },
        multipart: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        console.log("Mounted API", this.multipart)
        this.invoke()
    },
    updated() {
        console.log("Updated API", this.multipart)
        this.invoke()
    },
    methods: {
        invoke() {
            if (this.multipart)
                this.requestToRestAPIMultipart()
            else
                this.requestToRestAPI()
        },
        requestToRestAPI() {
            this.confirmationMessage = ''
            let axiosOptions = {
                method: this.httpMethod,
                url: this.endpoint
            }
            if (Object.entries(this.requestBody).length > 0) {
                axiosOptions.data = this.requestBody
            }

            this.$root.data.axios(axiosOptions)
                .then(response => {
                    this.$emit('handler-web-service-response', {
                        response: response.data,
                        endpoint: this.endpoint,
                        error: false,
                        withConfirmation: this.withConfirmation
                    })
                })
                .catch(err => {
                    console.error(err)
                    this.$emit('handler-web-service-response', {
                        response: err.response.data,
                        endpoint: this.endpoint,
                        error: true,
                        withConfirmation: this.withConfirmation
                    })
                    if (err.response.data.auth === false) {
                        this.$root.logOut(true)
                    }
                })
        },
        requestToRestAPIMultipart() {
            console.log(".....Aqui")
            this.confirmationMessage = ''
            let axiosOptions = {
                method: this.httpMethod,
                url: this.endpoint,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            let formData = new FormData()
            let arr = Object.entries(this.requestBody)
            if (arr.length > 0) {
                arr.forEach((v) => {
                    formData.append(v[0], v[1])
                })
                axiosOptions.data = formData
            }

            this.$root.data.axios(axiosOptions)
                .then(response => {
                    this.$emit('handler-web-service-response', {
                        response: response.data,
                        endpoint: this.endpoint,
                        error: false,
                        withConfirmation: this.withConfirmation
                    })
                })
                .catch(err => {
                    console.error(err)
                    this.$emit('handler-web-service-response', {
                        response: err.response.data,
                        endpoint: this.endpoint,
                        error: true,
                        withConfirmation: this.withConfirmation
                    })
                    if (err.response.data.auth === false) {
                        this.$root.logOut(true)
                    }
                })
        }
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
<template>
  <div v-if="$root.data.role === 'MASTER' || isActive" @click="onExpandable" class="sidebar-heading my-2 section-menu" :class="{'mb-4': (!isExpandable) }">
      <div class="row">
          <div class="col-sm-10 mb-2">
              <div class="row">
                    <div class="col-sm-2">
                        <img :src="icon" width="45" style="padding-right: 10px;">
                    </div>
                    <div class="col-sm-10" style="display: -webkit-flex; display: flex; align-items: center;">
                        {{ $root.utils.capitalizeFirstLetter(title) }}
                    </div>
                </div>
            </div>
            <div class="col-sm-2 pl-0 text-right mb-2" style="display: -webkit-flex; display: flex; justify-content: flex-end; align-items: center;">
                <i v-if="!isExpandable" class="fas fa-chevron-down"></i>
                <i v-else class="fas fa-chevron-up"></i>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'MenuGrouper',
    props: {
        index: Number,
        title: String,
        icon: String,
        isActive: {
            type: Boolean,
            default: false
        },
        isExpandable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onExpandable() {
            this.$emit('handler-expandable', this.index)
        }
    }
}
</script>

<style>
    .section-menu {
        padding-left: 4px;
        padding-right: 4px;
        color: #d0e2e8;
        font-size: 0.72rem;
        font-weight: 600;
        box-shadow: #99c1ee 0px 0.6px;
        cursor: pointer;
    }
    .sidebar-heading:hover {
        background: #59649d;
        color: #ffffff;
        transition: all 0.25s ease-
        -out;
    }
</style>
<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.systemSetting && $root.data.accessList.systemSetting.integration && $root.data.accessList.systemSetting.integration.active))">

      <div class="card shadow" style="margin-top: 85px!important;">

        <Headboard 
          :title="'Integración'"
          :icon-reference="'fas fa-link'"
          :consulting="sending"
          :applied-filters="false"
          :minimalist="$root.data.minimalist"
          :create-action-name="'Nueva Integración'"
          :hide-filters="true"
          :permission="($root.data.accessList.systemSetting.integration.permission === 'WRITE' && records.length === 0) ? 'WRITE' : 'READ'"
          @handler-refresh="handlerRefresh"
          @handler-show-filter="handlerShowFilter"
          @handler-show-form-save="handlerShowSavedForm"
        />

        <div class="card-body container-pager-grid">
              <CustomGrid
                :visible="!sending"
                :condensed="false"
                :titles="grid.titles"
                :mapping="grid.mapping"
                :records="records"
                :pagination="pagination"
                :permission="$root.data.accessList.systemSetting.integration.permission"
                @handler-show-form-save="handlerShowSavedForm"
                @handler-show-status-change="handlerShowStatusChange"
                @handler-show-confirmation-delete="handlerShowConfirmationDelete"
              />
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <ConfirmationDelete :visible="showConfirmationDelete" @handler-hide="handlerHideConfirmationDelete" @handler-delete="handlerDelete" />
      <SavedForm :visible="showSavedForm" :item="documentObj" @handler-visibility="handlerHideSavedForm" @handler-save="handlerSave" />
      <StatusChangeForm :visible="showStatusChange" :item="documentObj" @handler-hide-status-change="handlerHideStatusChange" @handler-status-change="handlerStatusChange" />
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" />
      <!-- End Modal Set -->        

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import CustomGrid from '../../../Widgets/CustomGrid.vue'
import ConfirmationDelete from './Forms/ConfirmationDelete.vue'
import SavedForm from './Forms/SavedForm.vue'
import StatusChangeForm from './Forms/StatusChangeForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
export default {
    name: 'Integrations',
    components: {
      Headboard,
      CustomGrid,
      ConfirmationDelete,
      SavedForm,
      StatusChangeForm,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        records: [],
        documentObj: {
          id: '',
          name: '',
          api: '',
          authentication: {
            endpoint: '',
            httpMethod: 'POST',
            bodyData: '',
            tokenTag: ''
          },
          markups: {
            endpoint: '',
            httpMethod: 'POST',
            delay: 60
          },
          employees: {
            endpoint: '',
            httpMethod: 'POST',
            delay: 60
          },
          status: ''
        },
        filters: {
          name: '',
          api: '',
          status: ''
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        hide: false,
        sending: false,
        showConfirmationDelete: false,
        showSavedForm: false,
        showStatusChange: false,
        pagination: {
          currentStart: 0,
          currentEnd: 0,
          total: 0,
          currentPage: 1,
          totalPage: 1,
          limit: 15
        },
        grid: {
          titles: [{name: 'Info'}, {name: 'Acciones', center: true}],
          mapping: [
            {
              mode: 'MULTI_FIELDS',
              ref: 'info',
              refs: [{
                title: 'Nombre',
                name: 'name',
              }, {
                title: 'API',
                name: 'api'
              }, {
                title: 'Estado',
                name: 'status',
                type: 'OPTIONS',
                class: {
                  ACTIVA: 'badge badge-success',
                  INACTIVA: 'badge badge-danger'
                }
              }]
            },
            {
              mode: 'ACTIONS',
              center: false,
              actions: [
                {
                  type: 'STATUS_CHANGE',
                  tooltip: {
                    ACTIVA: 'Desactivar integración',
                    INACTIVA: 'Activar integración'
                  },
                  class: {
                    ACTIVA: 'fas fa-power-off color-rojo-intermedio',
                    INACTIVA: 'fas fa-plug color-verde-fuerte'
                  },
                  handler: 'handler-show-status-change'
                },
                {
                  type: 'EDIT',
                  tooltip: 'Editar',
                  handler: 'handler-show-form-save'
                },
                {
                  type: 'DELETE',
                  tooltip: 'Eliminar',
                  handler: 'handler-show-confirmation-delete'
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.sending = false
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.pagination.currentPage = callback.response.currentPage
          this.pagination.totalPages = callback.response.totalPages
          this.pagination.currentStart = ((this.pagination.limit * (this.pagination.currentPage - 1)) + 1)
          this.pagination.currentEnd = ((this.pagination.limit * (this.pagination.currentPage - 1)) + callback.response.records.length)
          this.pagination.total = callback.response.totalRecords
          this.records = callback.response.records
        } else {
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.pagination.currentPage = 1
        this.consultBuilder()
      },
      handlerPreviousPage() {
        this.pagination.currentPage -= 1
        this.consultBuilder()
      },
      handlerNextPage() {
        this.pagination.currentPage += 1
        this.consultBuilder()
      },
      handlerShowFilter(v) {
        this.showFilters = v
      },
      handlerHideSavedForm() {
        this.showSavedForm = false
      },
      handlerShowSavedForm(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          name: (item && item.name) ? item.name : '',
          api: (item && item.api) ? item.api : '',
          authentication: {
            endpoint: (item && item.authentication && item.authentication.endpoint) ? item.authentication.endpoint : '',
            httpMethod: (item && item.authentication && item.authentication.httpMethod) ? item.authentication.httpMethod : 'POST',
            bodyData: (item && item.authentication && item.authentication.bodyData) ? item.authentication.bodyData : '',
            tokenTag: (item && item.authentication && item.authentication.tokenTag) ? item.authentication.tokenTag : ''
          },
          markups: {
            endpoint: (item && item.markups && item.markups.endpoint) ? item.markups.endpoint : '',
            httpMethod: (item && item.markups && item.markups.httpMethod) ? item.markups.httpMethod : 'POST',
            delay: (item && item.markups && item.markups.delay) ? item.markups.delay : 60
          },
          employees: {
            endpoint: (item && item.employees && item.employees.endpoint) ? item.employees.endpoint : '',
            httpMethod: (item && item.employees && item.employees.httpMethod) ? item.employees.httpMethod : 'POST',
            delay: (item && item.employees && item.employees.delay) ? item.employees.delay : 60
          },
          status: (item && item.status) ? item.status : ''
        }
        this.showSavedForm = true
      },
      handlerSave() {
        this.handlerHideSavedForm()
        this.saveBuilder(this.documentObj)
      },
      handlerShowStatusChange(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          name: (item && item.name) ? item.name : '',
          status: (item && item.status) ? item.status : ''
        }
        this.showStatusChange = true
      },
      handlerHideStatusChange() {
        this.showStatusChange = false
      },
      handlerStatusChange() {
        this.handlerHideStatusChange()
        this.statusChangeBuilder(this.documentObj)
      },
      handlerHideConfirmationDelete() {
        this.showConfirmationDelete = false
      },
      handlerShowConfirmationDelete(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : ''
        }
        this.showConfirmationDelete = true
      },
      handlerDelete() {
        this.handlerHideConfirmationDelete()
        this.deleteBuilder(this.documentObj.id)
      },

      consultBuilder() {
        this.hide = true
        this.httpConsumerData = {
          endpoint: '/integration/manager/list',
          method: 'post',
          withConfirmation: false,
          requestBody: {
            name: this.filters.name,
            api: this.filters.api,
            status: this.filters.status,
            page: this.pagination.currentPage,
            limit: this.pagination.limit
          }
        }
        this.sending = true
      },

      saveBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/integration/manager/',
          method: (item && item.id) ? 'put' : 'post',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            name: item.name,
            api: item.api,
            authentication: item.authentication,
            markups: item.markups,
            employees: item.employees,
            status: item.status
          }
        }

        this.sending = true
      },

      statusChangeBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/integration/manager/statusChange',
          method: 'put',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            status: item.status
          }
        }

        this.sending = true
      },

      deleteBuilder(id) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/integration/manager',
          method: 'delete',
          withConfirmation: true,
          requestBody: {
            documentId: id
          }
        }

        this.sending = true
      }

    }
}
</script>

<style>

</style>
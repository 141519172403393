module.exports = {
    API: {
        URL: 'http://192.241.156.118:3000/',
        //URL: 'http://localhost:3000/',
        TIMEOUT: 30000,
        HEADERS: {
            ACCESS_TOKEN: 'ariak-time-access-token'
        }
    },
    ICONS: {
        USER_ACCOUNT: '',
        EMPLOYEE_AVATAR: 'https://cdn0.iconfinder.com/data/icons/ikooni-outline-free-basic/128/free-17-256.png'
    },
    MESSAGES: {
        TRY_AGAIN_CONNECTION_ANY_ENDPOINT: 'Intente nuevamente, no fue posible ejecutar la petición.'
    },
    TIMER: {
        TIMEOUT: 28800000
    }
}

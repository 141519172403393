<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content" style="font-size: 80%;">
                            <form>
                                <div class="modal-body">
                                    <p class="color-gris-intermedio">
                                        <b class="color-gris">Configuración de la integración</b>
                                    </p>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Nombre</span>
                                                <input v-model="item.name" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el nombre de la integración">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">API</span>
                                                <input v-model="item.api" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba la URL o dirección del API">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card bg-light mb-2">
                                        <div class="card-body p-1">
                                            <div class="row mb-1">
                                                <div class="col-sm-6">
                                                    <span class="badge badge-pill badge-secondary">Autenticación</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Endpoint</span>
                                                        <input v-model="item.authentication.endpoint" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el endpoint">
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Method</span>
                                                        <select v-model="item.authentication.httpMethod" class="form-control form-control-sm" required>
                                                            <option value=''>--Seleccione una opción--</option>
                                                            <option value='GET'>GET</option>
                                                            <option value='POST'>POST</option>
                                                            <option value='PUT'>PUT</option>
                                                            <option value='DELETE'>DELETE</option>
                                                            <option value='PATH'>PATH</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Body Data</span>
                                                        <input v-model="item.authentication.bodyData" type="text" class="form-control form-control-sm custom-input-group" placeholder="Cuerpo de la petición">
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Token Tag</span>
                                                        <input v-model="item.authentication.tokenTag" type="text" class="form-control form-control-sm custom-input-group" placeholder="Nodo de token">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card bg-light mb-2">
                                        <div class="card-body p-1">
                                            <div class="row mb-1">
                                                <div class="col-sm-6">
                                                    <span class="badge badge-pill badge-secondary">Marcaciones</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Endpoint</span>
                                                        <input v-model="item.markups.endpoint" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el endpoint">
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Method</span>
                                                        <select v-model="item.markups.httpMethod" class="form-control form-control-sm" required>
                                                            <option value=''>--Seleccione una opción--</option>
                                                            <option value='GET'>GET</option>
                                                            <option value='POST'>POST</option>
                                                            <option value='PUT'>PUT</option>
                                                            <option value='DELETE'>DELETE</option>
                                                            <option value='PATH'>PATH</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Delay</span>
                                                        <input v-model="item.markups.delay" type="text" class="form-control form-control-sm custom-input-group" placeholder="Tiempo de ejecución en minutos">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card bg-light">
                                        <div class="card-body p-1">
                                            <div class="row mb-1">
                                                <div class="col-sm-6">
                                                    <span class="badge badge-pill badge-secondary">Empleados</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Endpoint</span>
                                                        <input v-model="item.employees.endpoint" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el endpoint">
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Method</span>
                                                        <select v-model="item.employees.httpMethod" class="form-control form-control-sm" required>
                                                            <option value=''>--Seleccione una opción--</option>
                                                            <option value='GET'>GET</option>
                                                            <option value='POST'>POST</option>
                                                            <option value='PUT'>PUT</option>
                                                            <option value='DELETE'>DELETE</option>
                                                            <option value='PATH'>PATH</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <span class="color-gris">Delay</span>
                                                        <input v-model="item.employees.delay" type="text" class="form-control form-control-sm custom-input-group" placeholder="Tiempo de ejecución en minutos">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onSave()" type="submit" class="btn btn-success btn-sm">{{ item.id === '' ?  'Crear' : 'Guardar cambios' }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
export default {
    name: 'UserAccountsSavedForm',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-visibility', false)
        },
        onSave() {
            this.$emit('handler-save')
        }
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
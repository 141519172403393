<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body">
                                    <p class="color-rojo-fuerte"><b>Eliminar Empleado</b> ({{item.fullname}})</p>
                                    <span class="color-gris-intermedio">¿Está seguro que quiere eliminar este cargo?</span>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onDelete()" type="submit" class="btn btn-danger btn-sm">Eliminar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'PositionConfirmationDelete',
    props: {
        item: Object,
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-hide')
        },
        onDelete() {
            this.$emit('handler-delete')
        }
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form @submit.prevent="onSave()">
                                <div class="modal-body">
                                    <p class="color-verde-fuerte"><b>Nuevo Festivo</b> <span v-if="!item.withSelection">({{$root.utils.showFormatFriendlyDate(item.day)}})</span></p>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div v-if="item.withSelection" class="form-group">
                                                <span class="color-gris">Fecha</span>
                                                <input v-model="item.day" type="date" class="form-control form-control-sm custom-input-group" required />
                                            </div>
                                            <div>
                                                <span v-if="!item.isSunday" class="color-gris-intermedio">Recuerde que este día será parte de los festivos del período <b>{{(new Date().getFullYear())}}</b>.</span>
                                                <span v-else class="color-gris-intermedio"><b>Nota</b>: No será posible crear los domingos como días festivos.</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-show="(errorMessage !== '')" class="alert alert-danger" role="alert" style="font-size: 90%;">
                                        <i class="fas fa-exclamation-triangle"></i> {{errorMessage}}
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button v-show="!item.isSunday" type="submit" class="btn btn-success btn-sm">Crear</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
export default {
    name: 'HolidaySavedForm',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-visibility', false)
        },
        onSave() {
            this.$emit('handler-save')
        }
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
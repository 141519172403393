<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.collaborators && $root.data.accessList.collaborators.areas && $root.data.accessList.collaborators.areas.active))">

      <div class="card shadow" style="margin-top: 85px!important;">
        
          <Headboard 
            :title="'Administración de Áreas'"
            :icon-reference="'fas fa-network-wired'"
            :loading="sending"
            :applied-filters="appliedFilters"
            :minimalist="$root.data.minimalist"
            :create-action-name="'Nueva Área'"
            :permission="$root.data.accessList.collaborators.areas.permission"
            @handler-refresh="handlerRefresh"
            @handler-show-filter="handlerShowFilter"
            @handler-show-form-save="handlerShowSavedForm"
          />

          <div class="card-body container-pager-grid">
              <Pager 
                :pagination="pagination"
                :records-count="records.length"
                @handler-previous-page="handlerPreviousPage"
                @handler-next-page="handlerNextPage"
              />

              <CustomGrid
                :visible="!sending"
                :titles="grid.titles"
                :mapping="grid.mapping"
                :records="records"
                :pagination="pagination"
                :permission="$root.data.accessList.collaborators.areas.permission"
                @handler-show-form-save="handlerShowSavedForm"
                @handler-show-status-change="handlerShowStatusChange"
                @handler-show-confirmation-delete="handlerShowConfirmationDelete"
              />              
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <ConfirmationDelete :visible="showConfirmationDelete" :item="documentObj" @handler-hide="handlerHideConfirmationDelete" @handler-delete="handlerDelete" />
      <FilteredOutForm :visible="showFilters" :filters="filters" @handler-show-filter="handlerShowFilter"  @handler-reset-filter="handlerResetFilter" @handler-apply-filter="handlerApplyFilter" />
      <SavedForm :visible="showSavedForm" :item="documentObj" @handler-visibility="handlerHideSavedForm" @handler-save="handlerSave" />
      <StatusChangeForm :visible="showStatusChange" :item="documentObj" @handler-hide-status-change="handlerHideStatusChange" @handler-status-change="handlerStatusChange" />
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import Pager from '../../../Widgets/Pager.vue'
import CustomGrid from '../../../Widgets/CustomGrid.vue'
import ConfirmationDelete from './Forms/ConfirmationDelete.vue'
import FilteredOutForm from './Forms/FilteredOutForm.vue'
import SavedForm from './Forms/SavedForm.vue'
import StatusChangeForm from './Forms/StatusChangeForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
export default {
    name: 'Areas',
    components: {
      Headboard,
      Pager,
      CustomGrid,
      ConfirmationDelete,
      FilteredOutForm,
      SavedForm,
      StatusChangeForm,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        roles: [],
        records: [],
        documentObj: {
          id: '',
          code: '',
          name: '',
          status: ''
        },
        filters: {
          code: '',
          name: '',
          status: ''
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        hide: false,
        sending: false,
        appliedFilters: false,
        showConfirmationDelete: false,
        showFilters: false,
        showSavedForm: false,
        showStatusChange: false,
        pagination: {
          currentStart: 0,
          currentEnd: 0,
          total: 0,
          currentPage: 1,
          totalPage: 1,
          limit: 15
        },
        grid: {
          titles: [{name: 'Código'}, {name: 'Nombre'}, {name: 'Estado', center: true}, {name: 'Acciones', center: true}],
          mapping: [
            {
              ref: 'code'
            },
            {
              ref: 'name'
            },
            {
                mode: 'ONLY_ACTION',
                ref: 'status',
                tooltip: {
                    ACTIVA: 'Deshabilitar área',
                    INACTIVA: 'Habilitar área'
                },
                class: {
                    ACTIVA: 'fas fa-check-circle color-verde-intermedio',
                    INACTIVA: 'fas fa-ban color-rojo-intermedio'
                },
                handler: 'handler-show-status-change'
            },
            {
              mode: 'ACTIONS',
              center: false,
              actions: [
                {
                  type: 'EDIT',
                  tooltip: 'Editar',
                  handler: 'handler-show-form-save'
                },
                {
                  type: 'DELETE',
                  tooltip: 'Eliminar',
                  handler: 'handler-show-confirmation-delete'
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.sending = false
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.pagination.currentPage = callback.response.currentPage
          this.pagination.totalPages = callback.response.totalPages
          this.pagination.currentStart = ((this.pagination.limit * (this.pagination.currentPage - 1)) + 1)
          this.pagination.currentEnd = ((this.pagination.limit * (this.pagination.currentPage - 1)) + callback.response.records.length)
          this.pagination.total = callback.response.totalRecords
          this.records = callback.response.records
        } else {
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.pagination.currentPage = 1
        this.consultBuilder()
      },
      handlerPreviousPage() {
        this.pagination.currentPage -= 1
        this.consultBuilder()
      },
      handlerNextPage() {
        this.pagination.currentPage += 1
        this.consultBuilder()
      },
      handlerShowFilter(v) {
        this.showFilters = v
      },
      handlerResetFilter() {
        this.filters = {
          code: '',
          name: '',
          status: ''
        }
        this.appliedFilters = false
        this.showFilters = false
        this.consultBuilder()
      },
      handlerApplyFilter() {
        this.appliedFilters = true
        this.showFilters = false
        this.consultBuilder()
      },
      handlerHideSavedForm() {
        this.showSavedForm = false
      },
      handlerShowSavedForm(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          code: (item && item.code) ? item.code : '',
          name: (item && item.name) ? item.name : '',
          status: (item && item.status) ? item.status : ''
        }
        this.showSavedForm = true
      },
      handlerSave() {
        this.handlerHideSavedForm()
        this.saveBuilder(this.documentObj)
      },
      handlerShowStatusChange(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          code: (item && item.code) ? item.code : '',
          name: (item && item.name) ? item.name : '',
          status: (item && item.status) ? item.status : ''
        }
        this.showStatusChange = true
      },
      handlerHideStatusChange() {
        this.showStatusChange = false
      },
      handlerStatusChange() {
        this.handlerHideStatusChange()
        this.statusChangeBuilder(this.documentObj)
      },
      handlerHideConfirmationDelete() {
        this.showConfirmationDelete = false
      },
      handlerShowConfirmationDelete(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          code: (item && item.code) ? item.code : ''
        }
        this.showConfirmationDelete = true
      },
      handlerDelete() {
        this.handlerHideConfirmationDelete()
        this.deleteBuilder(this.documentObj.id)
      },

      consultBuilder() {
        this.hide = true
        this.httpConsumerData = {
          endpoint: '/area/manager/list',
          method: 'post',
          withConfirmation: false,
          requestBody: {
            code: this.filters.code,
            name: this.filters.name,
            status: this.filters.status,
            page: this.pagination.currentPage,
            limit: this.pagination.limit
          }
        }
        this.sending = true
      },

      saveBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/area/manager/',
          method: (item && item.id) ? 'put' : 'post',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            code: item.code,
            name: item.name,
            status: item.status
          }
        }

        this.sending = true
      },

      statusChangeBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/area/manager/statusChange',
          method: 'put',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            status: item.status
          }
        }

        this.sending = true
      },

      deleteBuilder(id) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/area/manager',
          method: 'delete',
          withConfirmation: true,
          requestBody: {
            documentId: id
          }
        }

        this.sending = true
      }

    }
}
</script>

<style>

</style>
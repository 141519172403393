<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 mb-2">
                    <span class="color-gris-normal">
                        <i class="fas fa-sun"></i>
                        <b> Horario diurno</b>
                    </span>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Inicia</span>
                        <input v-model="settings.diurnal.from" type="time" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Termina</span>
                        <input v-model="settings.diurnal.to" type="time" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12 mb-2">
                    <span class="color-gris-normal">
                        <i class="fas fa-moon"></i>
                        <b> Horario nocturno</b>
                    </span>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Inicia</span>
                        <input v-model="settings.nocturnal.from" type="time" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Termina</span>
                        <input v-model="settings.nocturnal.to" type="time" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Schedule',
    props: {
        settings: {
            type: Object,
            require: true
        }
    }
}
</script>

<style>

</style>
<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg" style="margin-top: 15px;" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body scroll-y-custom">
                                    <p class="color-gris-intermedio">
                                        <b class="color-gris">
                                            {{ !item.id ? 'Nuevo Empleado' : 'Editar Empleado'}}
                                        </b>
                                    </p>

                                    <div class="row">
                                        <div class="col-4">
                                            <div>
                                                <img :src="accountPhotoURL" class="avatar-customized" alt="..." />
                                            </div>
                                            <div class="mt-2">
                                                <input type="file" ref="fileAvatar" class="form-control-file form-control-sm"  accept="image/*" @change="onFileUpload($event)">
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <span class="color-gris">Código</span>
                                                        <input v-model="item.code" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el código del empleado">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <span class="color-gris">Cédula</span>
                                                        <input v-model="item.idNumber" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el número de identificación">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <span class="color-gris">Nombre completo</span>
                                                        <input v-model="item.fullname" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el nombre del empleado">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <span class="color-gris">Email</span>
                                                        <input v-model="item.email" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el email del empleado">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-1">
                                        <div class="col-12">
                                            <hr class="hr-customized">
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Número de teléfono</span>
                                                <input v-model="item.phoneNumber" type="number" class="form-control form-control-sm custom-input-group" placeholder="Escriba el número de teléfono del empleado" min="0">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Dirección de residencia</span>
                                                <input v-model="item.address" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba la dirección de residencia">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Área</span>
                                                <select v-model="item.areaId" class="form-control form-control-sm" required>
                                                    <option value=''>--Seleccione una opción--</option>
                                                    <option v-for="a in areas" :key="a._id" :value="a._id">{{a.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Grupo</span>
                                                <select v-model="item.groupId" class="form-control form-control-sm" required>
                                                    <option value=''>--Seleccione una opción--</option>
                                                    <option v-for="g in groups" :key="g._id" :value="g._id">{{g.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Cargo</span>
                                                <select v-model="item.positionId" class="form-control form-control-sm" required>
                                                    <option value=''>--Seleccione una opción--</option>
                                                    <option v-for="p in positions" :key="p._id" :value="p._id">{{p.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Centro de costo</span>
                                                <select v-model="item.costCenterId" class="form-control form-control-sm" required>
                                                    <option value=''>--Seleccione una opción--</option>
                                                    <option v-for="c in costCenters" :key="c._id" :value="c._id">({{c.code}}) {{c.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Tipo de contrato</span>
                                                <select v-model="item.contractType" class="form-control form-control-sm" required>
                                                    <option value=''>--Seleccione una opción--</option>
                                                    <option value='1'>A TÉRMINO FIJO</option>
                                                    <option value='2'>A TÉRMINO INDEFINIDO</option>
                                                    <option value='3'>DE APRENDIZAJE</option>
                                                    <option value='4'>TEMPORAL, OCASIONAL O ACCIDENTAL</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onSave()" type="submit" class="btn btn-success btn-sm">{{ item.id === '' ?  'Crear' : 'Guardar cambios' }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
export default {
    name: 'EmployeeSavedForm',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        },
        areas: {
            type: Array,
            default() { return [] }
        },
        costCenters: {
            type: Array,
            default() { return [] }
        },
        groups: {
            type: Array,
            default() { return [] }
        },
        positions: {
            type: Array,
            default() { return [] }
        },
        accountPhotoURL: {
            type: String,
            default: ''
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-visibility', false)
        },
        onSave() {
            this.$emit('handler-save')
        },
        onFileUpload(e) {
            this.$emit('handler-file-upload', {
                fileObject: this.$refs.fileAvatar.files[0],
                tempPath: URL.createObjectURL(e.target.files[0])
            })
        }
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
    .avatar-customized {
        max-width: 190px; 
        min-width: 190px; 
        max-height: 190px; 
        min-height: 190px; 
        object-fit: cover;
        border-radius: 10px; 
        box-shadow: #dfdfe8 -2px 1px 10px 2px;
    }
</style>
<template>
  <li v-if="$root.data.role === 'MASTER' || isActive" v-show="isVisible" class="nav-item active">
        <router-link @click="onMarkAsActive" class="nav-link option-menu" :to="link" :class="{ selected2: isSelected }">
            <table>
                <tbody>
                    <tr>
                        <td style="padding-left: 4px;">
                            <span class="font-item">{{ $root.utils.capitalizeFirstLetter(title) }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </router-link>
    </li>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        id: Number,
        title: String,
        link: String,
        isActive: {
            type: Boolean,
            default: false
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onMarkAsActive() {
            this.$emit('handler-mark-as-active', this.id)
        }
    }
}
</script>

<style>
    .option-menu {
        margin-left: 45px;
        padding-top: 0; 
        padding-bottom: 0.5rem;
        line-height: 15px;
        color: #ffffff;
    }
    .option-menu:hover {
        color: #c7ece9;
    }
    .selected2 {
        color: #f1e75a;
    }
    .font-item {
        font-size: 0.68rem;
        font-weight: 400;
    }
    @media screen and (max-width: 500px) {
        .option-menu {
            margin-left: 0px;
            line-height: 15px;
            padding-left: 12px;
            padding-right: 12px;
        }
    }
</style>
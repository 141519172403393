<template>
  <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body">
                                    <p>
                                        <i class="fas fa-times-circle color-rojo-fuerte"></i>
                                        <b class="color-rojo-fuerte pl-1">Cierre de Período</b>
                                    </p>
                                    <p class="text-justify size-90"><b>¿Está seguro que quiere cerrar este período?</b>, una vez cerrado este período se abrirá automáticamente el siguiente.</p>
                                        
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onStatusChange()" type="submit" class="btn btn-sm btn-danger" >Cerrar período</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'PeriodStatusChangeForm',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-hide-status-change')
        },
        onStatusChange() {
            this.$emit('handler-status-change')
        }
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Cantidad de horas laborales de ley</span>
                        <input v-model="settings.hoursOfLawPerShift" type="number" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Tiempo de Gracia a la Entrada (minutos)</span>
                        <input v-model="settings.graceMinutesOnEntry" type="number" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Tiempo de Gracia a la Salida (minutos)</span>
                        <input v-model="settings.graceMinutesOnExit" type="number" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <span class="color-gris">Trabajo continuo (24/7)</span>
                    <div class="custom-control custom-switch mt-0">
                        <input v-model="settings.continuousWork" class="custom-control-input" type="checkbox" value="" id="__continuousWork">
                        <label class="custom-control-label" for="__continuousWork"></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Shifts',
    props: {
        settings: {
            type: Object,
            require: true
        }
    }
}
</script>

<style>

</style>
import { createRouter, createWebHistory } from 'vue-router'
/**
 * Module: System Settings
 */
import Roles from '../components/Modules/SystemSettings/Roles/'
import UserAccounts from '../components/Modules/SystemSettings/UserAccounts/'
import Integrations from '../components/Modules/SystemSettings/Integrations/'
/**
 * Module: Collaborators
 */
import CostCenters from '../components/Modules/Collaborators/CostCenters/'
import Projects from '../components/Modules/Collaborators/Projects/'
import Areas from '../components/Modules/Collaborators/Areas/'
import Groups from '../components/Modules/Collaborators/Groups/'
import Positions from '../components/Modules/Collaborators/Positions/'
import Employees from '../components/Modules/Collaborators/Employees/'

/**
 * Module: General parameters
 */
import HourTypes from '../components/Modules/GeneralParameters/HourTypes/'
import NewsTypes from '../components/Modules/GeneralParameters/NewsTypes/'
import Rules from '../components/Modules/GeneralParameters/Rules/'
import Holidays from '../components/Modules/GeneralParameters/Holidays/'
import Shifts from '../components/Modules/GeneralParameters/Shifts/'

/**
 * Module: Gestion
 */
import Periods from '../components/Modules/Gestion/Periods/'
import HoursRegistry from '../components/Modules/Gestion/HoursRegistry/'

const routes = [
  {
    path: '/roles',
    name: 'Roles',
    component: Roles
  },
  {
    path: '/userAccounts',
    name: 'User Accounts',
    component: UserAccounts
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: Integrations
  },
  {
    path: '/costCenters',
    name: 'Cost Centers',
    component: CostCenters
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups
  },
  {
    path: '/positions',
    name: 'Positions',
    component: Positions
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees
  },
  {
    path: '/hourTypes',
    name: 'Hour Types',
    component: HourTypes
  },
  {
    path: '/newsTypes',
    name: 'News Types',
    component: NewsTypes
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/holidays',
    name: 'Holidays',
    component: Holidays
  },
  {
    path: '/shifts',
    name: 'Shifts',
    component: Shifts
  },
  {
    path: '/periods',
    name: 'Periods',
    component: Periods
  },
  {
    path: '/hoursRegistry',
    name: 'Hours Registry',
    component: HoursRegistry
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body">
                                    <p class="color-gris-intermedio">
                                        <b class="color-gris">
                                            {{ !item.id ? 'Nuevo Rol' : 'Editar Rol'}}
                                        </b>
                                    </p>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Nombre</span>
                                                <input v-model="item.name" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba el nombre del rol">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Estilo:</span>
                                                <select v-model="item.style" class="form-control form-control-sm" required>
                                                    <option value=''>--Seleccione una opción--</option>
                                                    <option value='NORMAL'>Normal</option>
                                                    <option value='MINIMALISTA'>Minimalista</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <AccessSettingsForm
                                        :settings="item.accessSettings"
                                    />

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onSave()" type="submit" class="btn btn-success btn-sm">{{ item.id === '' ?  'Crear' : 'Guardar cambios' }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
import AccessSettingsForm from './AccessSettingsForm.vue'
export default {
    name: 'RolesSavedForm',
    components: {
        AccessSettingsForm
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-visibility', false)
        },
        onSave() {
            this.$emit('handler-save')
        }
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
<template>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <div class="form-group">
                    <span class="color-gris">Fecha de registro</span>
                    <input v-model="item.registryDate" type="date" class="form-control form-control-sm custom-input-group" :min="period.from" :max="period.to" @keydown="keyboardPrevent" required />
                </div>
            </div>
        </div>
    </div>
    <div class="row card mx-0 mb-2 bg-gray-100" v-for="(range, index) in item.timeRanges" :key="index">
        <div class="row mt-1 ml-0" v-if="index > 0">
            <div class="col-sm-12">
                <a @click="onRemoveTimeRange(index)" style="cursor: pointer;">
                    <i class="fas fa-times color-rojo-intermedio" style="font-size: 120%;"></i>
                </a>
            </div>
        </div>
        <div class="row px-3" :class="[{'pt-3': (index === 0)}, {'py-0': (index > 0)}]">
            <div class="col-sm-6">
                <div class="form-group">
                    <input type="time" class="form-control form-control-sm custom-input-group" :value="range.from" @change="onUpdateTimeRange($event, index, 'from')">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <input type="time" class="form-control form-control-sm custom-input-group" :value="range.to" @change="onUpdateTimeRange($event, index, 'to')">
                </div>
            </div>
        </div>
    </div>

    <div class="row" v-if="addTimeRange && item.timeRanges.length < 3">
        <div class="col-sm-12 text-center">
            <a @click="onAddTimeRange()" style="cursor: pointer;">
                <i class="fas fa-plus-circle color-verde-intermedio" style="font-size: 120%;"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HoursRegistryTimesSavedForm',
    props: {
        item: {
            type: Object,
            required: true
        },
        period: {
            type: Object,
            required: true
        },
        addTimeRange: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        keyboardPrevent(e) {
            return e.preventDefault()
        },
        onAddTimeRange() {
            this.$emit('handler-add-time-range')
        },
        onUpdateTimeRange(event, index, position) {
            this.$emit('handler-update-time-range', {
                event, 
                index, 
                position
            })
        },
        onRemoveTimeRange(index) {
            this.$emit('handler-remove-time-range', index)
        }
    }
}
</script>
<template>
  <div id="app" :class="{'login-background': !data.token}">
    <login v-if="(!data.token)"></login>
    <principal v-else></principal>
  </div>
</template>

<script>
import Login from './components/Login.vue'
import Principal from './components/Principal.vue'
import Config from './config'
import Axios from 'axios'
import Utils from './utils/'
import { reactive, onMounted } from 'vue'

export default {
  name: 'App',
  components: {
    Login,
    Principal
  },
  setup() {
    const data = reactive({
      axios: null,
      baseEndpoint: Config.API.URL,
      token: '',
      expiredSession: false,
      fullname: '',
      role: '',
      photo: '',
      accessList: null,
      minimalist: false,
      timers: {
        exit: null
      },
      company: {
        logo: '',
        name: '',
        nit: ''
      },
      constants: Object.freeze({
        ONLY_DATE: true,
        DATE_AND_TIME: true,
        FULL_TIME: true
      })
    })

    const utils = reactive(Utils)

    onMounted(() => {
      console.log("Mounted");
      start()
    })

    const start = () => {
      httpConsumerInitialize()
      data.token = localStorage.token
      data.fullname = localStorage.fullname
      data.role = localStorage.role
      data.accessList = (localStorage.accessList !== null && localStorage.accessList !== '' && localStorage.accessList !== undefined) ? JSON.parse(localStorage.accessList) : null
      data.minimalist = (localStorage.minimalist === 'true')
      data.photo = (localStorage.photo) ? localStorage.photo : Config.ICONS.USER_ACCOUNT
      // Company
      data.company.logo = (localStorage.logo) ? localStorage.logo : ''
      data.company.name = (localStorage.companyName) ? localStorage.companyName : ''
      data.company.nit = (localStorage.companyNit) ? localStorage.companyNit : ''
      setToken(localStorage.token)
      restartTimer()
    }

    const httpConsumerInitialize = () => {
      data.axios = Axios.create({
        baseURL: data.baseEndpoint,
        timeout: Config.API.TIMEOUT,
        withCredentials: false
      })
    }

    const setToken = (token) => {
      localStorage.token = data.token = token
      data.axios.defaults.headers.common[Config.API.HEADERS.ACCESS_TOKEN] = token
    }

    const logOut = (closedByTimer = false) => {
      console.log("Está saliendo...", (new Date()))
      localStorage.token = data.token = ''
      localStorage.fullname = data.fullname = ''
      localStorage.role = data.role = ''
      data.expiredSession = closedByTimer
    }

    const restartTimer = () => {
        if (!data.token) {
          console.log("No hay token", (new Date()))
          return
        }
        console.log("Timer started", (new Date()))
        clearTimeout(data.timers.exit)
        data.timers.exit = setTimeout(() => {
            console.log("Timer finished", (new Date()))
            if (data.token !== '') {
              logOut(true)
            }
        }, Config.TIMER.TIMEOUT)
    }

    return {
      data,
      utils,
      setToken,
      logOut,
      restartTimer
    }
  }
}
</script>

<style scoped>
.login-background {
    background-image:  url('../public/img/background_ariak_time.png');
    background-size: contain;
    background-position: left top;
    background-repeat: repeat;
}
</style>
<template>
  <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-fullscreen" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLongTitle">Vista Calendario - Configuración de Festivos</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onHide()">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <YearCalendar
                                v-model="year"
                                :activeDates="activeDates"
                                @toggleDate="toggleDate"
                                prefixClass="your_customized_wrapper_class"
                                :activeClass="activeClass"
                                :showYearSelector="false"
                                :lang="'es'"
                              ></YearCalendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import YearCalendar from 'vue-material-year-calendar'

export default {
  components: { YearCalendar },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    activeDates: {
      type: Array,
      default() { return [] }
    }
  },
  data () {
    return {
      year: 2021,
      activeClass: '',
    }
  },
  mounted() {
    this.activesDatesLoad()
  },
  updated() {
    this.activesDatesLoad()
  },
  methods: {
    activesDatesLoad() {
      
    },
    toggleDate (dateInfo) {
      this.$emit('handler-day-selector', dateInfo)
    },
    onHide() {
      this.$emit('handler-hide-view-calendar')
    }
  }
}
</script>

<style lang="stylus">
.your_customized_wrapper_class
  background-color: #0aa
  color: white
  &.red
    background-color: red
    color: white
    &:after
      background-size 100% 100%
  &.blue
    background-color: #0000aa
    color: white
  &.your_customized_classname
    background-color: yellow
    color: black

</style>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    .modal-fullscreen {
      width: 100vw; 
      max-width: none; 
      height: 100%; 
      margin: 0;
    }

    .modal-content .modal-body {
      max-height: 45vw;
      overflow: hidden auto;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */

    @media screen and (min-width: 500px) and (max-width: 900px) {
        .modal-content {
          max-height: 75vw;
          overflow: hidden auto;
        }
    }
</style>
<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.gestion && $root.data.accessList.gestion.hoursRegistry && $root.data.accessList.gestion.hoursRegistry.active))">

      <div class="card shadow" style="margin-top: 85px!important;">
        
          <Headboard 
            :title="'Registro de Horas'"
            :icon-reference="'fas fa-business-time'"
            :loading="sending"
            :applied-filters="appliedFilters"
            :minimalist="$root.data.minimalist"
            :create-action-name="'Nuevo Registro'"
            :permission="validatePermissionTypeForCreatingANewRecord($root.data.accessList.gestion.hoursRegistry.permission)"
            @handler-refresh="handlerRefresh"
            @handler-show-filter="handlerShowFilter"
            @handler-show-form-save="handlerShowSavedForm"
          />

          <div class="card-body container-pager-grid-2">
              <Pager
                :excel-download-active="['GR_WRITE', 'GENERAL_READ'].includes(this.$root.data.accessList.gestion.hoursRegistry.permission)"
                :period-indicator-active="true"
                :pagination="pagination"
                :records-count="records.length"
                @handler-previous-page="handlerPreviousPage"
                @handler-next-page="handlerNextPage"
                @handler-excel-donwload="handlerExcelDownload"
              />

              <CustomGrid
                :visible="!sending"
                :condensed="true"
                :titles="grid.titles"
                :mapping="grid.mapping"
                :records="records"
                :pagination="pagination"
                :permission="validatePermissionTypeForCreatingANewRecord($root.data.accessList.gestion.hoursRegistry.permission)"
                @handler-show-edition-form="handlerShowEditionForm"
                @handler-show-confirmation-delete="handlerShowConfirmationDelete"
              />              
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <FilteredOutForm :visible="showFilters" :filters="filters" :cost-centers="costCenters" :projects="projects" :employees="employees" @handler-show-filter="handlerShowFilter"  @handler-reset-filter="handlerResetFilter" @handler-apply-filter="handlerApplyFilter" />
      <SavedForm :visible="showSavedForm" :item="documentObj" :cost-centers="costCenters" :projects="projects" :employees="employees" :period="period" @handler-visibility="handlerHideSavedForm" @handler-save="handlerSave" />
      <EditionForm :visible="showEditionForm" :item="documentObj" :cost-centers="costCenters" :projects="projects" :employees="employees" :period="period" @handler-hide-edition-form="handlerHideEditionForm" @handler-edit-registry="handlerEditRegistry" />
      <StatusChangeForm :visible="showStatusChange" :item="documentObj" @handler-hide-status-change="handlerHideStatusChange" @handler-status-change="handlerStatusChange" />
      <ConfirmationDelete :visible="showConfirmationDelete" :item="documentObj" @handler-hide-deletion-form="handlerHideConfirmationDelete" @handler-delete-registry="handlerDeleteRegistry" />
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :with-error="responseIsFailed" :message="confirmationMessage" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import XLSX from 'xlsx';
import Headboard from '../../../Widgets/Headboard.vue'
import Pager from '../../../Widgets/Pager.vue'
import CustomGrid from '../../../Widgets/CustomGrid.vue'
import FilteredOutForm from './Forms/FilteredOutForm.vue'
import SavedForm from './Forms/SavedForm/index.vue'
import EditionForm from './Forms/EditionForm.vue'
import ConfirmationDelete from './Forms/ConfirmationDelete.vue'
import StatusChangeForm from './Forms/StatusChangeForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
export default {
    name: 'HoursRegistry',
    components: {
      Headboard,
      Pager,
      CustomGrid,
      FilteredOutForm,
      SavedForm,
      EditionForm,
      ConfirmationDelete,
      StatusChangeForm,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        costCenters: [],
        projects: [],
        employees: [],
        records: [],
        periodInfo: '',
        period: {
          from: '',
          to: '',
        },
        documentObj: {
          id: '',
          costCenterId: '',
          projectId: '',
          employeeId: '',
          employees: [],
          employee: null,
          item: null,
          registryDate: '',
          timeRanges: [],
          timeRange: { from: '', to: '' },
          status: ''
        },
        filters: {
          costCenterId: '',
          projectId: '',
          employeeId: '',
          status: 'PENDING_REVIEW'
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        responseIsFailed: false,
        hide: false,
        sending: false,
        appliedFilters: false,
        showFilters: false,
        showSavedForm: false,
        showStatusChange: false,
        showEditionForm: false,
        showConfirmationDelete: false,
        pagination: {
          currentStart: 0,
          currentEnd: 0,
          total: 0,
          currentPage: 1,
          totalPage: 1,
          limit: 20
        },
        download: {
          downloading: false,
          building: false,
          limit: 1000,
          currentPage: 1,
          totalPages: 1,
          rows: []
        },
        grid: {
          titles: [{name: 'Información'}, {name: 'Empleado'}, {name: 'Rango de tiempo'}, {name: 'Acciones', center: true}],
          mapping: [
            {
              mode: 'MULTI_FIELDS',
              ref: 'info',
              refs: [{
                title: 'Centro de Costo',
                name: 'costCenter',
                iconClass: 'fas fa-hand-holding-usd pr-1 color-azul-contundente'
              }, {
                title: 'Proyecto',
                name: 'project',
                iconClass: 'far fa-lightbulb pr-1 color-amarillo-fuerte'
              }, {
                title: 'Estado',
                name: 'status',
                iconClass: "fas fa-info-circle pr-1 color-gris-normal",
                type: 'OPTIONS',
                class: {
                  PENDIENTE: 'badge minibox-warning',
                  APROBADO: 'badge minibox-success',
                  RECHAZADO: 'badge minibox-danger'
                }
              }]
            },
            {
              mode: 'MULTI_FIELDS',
              ref: 'employee',
              refs: [{
                title: 'Empleado',
                name: 'fullname',
                iconClass: 'fas fa-user pr-1 color-gris-normal'
              }, {
                title: 'CC',
                name: 'idNumber',
                iconClass: 'fas fa-id-card pr-1 color-gris-normal'
              }]
            },
            {
              mode: 'MULTI_FIELDS',
              ref: 'time',
              refs: [{
                title: 'Fecha de registro',
                name: 'registryDate',
                iconClass: 'fas fa-calendar-alt pr-1 color-gris-intermedio'
              }],
              inlineRefs: [{
                title: 'Hora de entrada',
                name: 'from',
                iconClass: 'far fa-clock pr-1 color-verde-intermedio'
              }, {
                title: 'Hora de salida',
                name: 'to',
                iconClass: 'far fa-clock pr-1 color-rojo-suave'
              }]
            },
            {
              mode: 'ACTIONS',
              center: false,
              actions: [
                {
                  type: 'EDIT',
                  tooltip: 'Editar registro de hora',
                  handler: 'handler-show-edition-form'
                },
                {
                  type: 'DELETE',
                  tooltip: 'Eliminar registro de hora',
                  handler: 'handler-show-confirmation-delete'
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.processResponse(callback);
      },
      processResponse(callback) {
        if (callback.withConfirmation) {
          this.responseIsFailed = callback.error
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.sending = false
          this.pagination.currentPage = callback.response.currentPage
          this.pagination.totalPages = callback.response.totalPages
          this.pagination.currentStart = ((this.pagination.limit * (this.pagination.currentPage - 1)) + 1)
          this.pagination.currentEnd = ((this.pagination.limit * (this.pagination.currentPage - 1)) + callback.response.records.length)
          this.pagination.total = callback.response.totalRecords
          this.records = callback.response.records
          this.costCenters = callback.response.costCenters
          this.projects = callback.response.projects
          this.employees = callback.response.employees
          this.period = {
            from: callback.response.period.from,
            to: callback.response.period.to
          }          
        } else if (callback.endpoint.indexOf('/download') > -1) {
          this.periodInfo = callback.response.periodInfo
          this.download.totalPages = callback.response.totalPages
          if (callback.response.records && callback.response.records.length > 0) {
            callback.response.records.forEach(r => {
              this.download.rows.push(r)
            })
          }

          if (this.download.currentPage < this.download.totalPages) {
            this.download.currentPage = callback.response.currentPage + 1
            this.requestDownload()
          } else {
            this.download.currentPage = 1
            this.download.totalPages = 1
            this.download.downloading = false
            this.download.building = true
            const rows = this.download.rows
            this.download.rows = []
            this.xlxsBuild(rows)
          }

        } else {
          this.sending = false
          if (callback.error) {
            return;
          }
          this.handlerHideSavedForm()
          this.handlerHideEditionForm()
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.pagination.currentPage = 1
        this.consultBuilder()
      },
      handlerPreviousPage() {
        this.pagination.currentPage -= 1
        this.consultBuilder()
      },
      handlerNextPage() {
        this.pagination.currentPage += 1
        this.consultBuilder()
      },
      handlerExcelDownload() {
        this.requestDownload()
      },
      handlerShowFilter(v) {
        this.showFilters = v
      },
      handlerResetFilter() {
        this.filters = {
          costCenterId: '',
          projectId: '',
          employeeId: '',
          status: 'PENDING_REVIEW',
        }
        this.appliedFilters = false
        this.showFilters = false
        this.consultBuilder()
      },
      handlerApplyFilter() {
        this.appliedFilters = true
        this.showFilters = false
        this.consultBuilder()
      },
      handlerHideSavedForm() {
        this.showSavedForm = false
      },
      handlerShowSavedForm(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          costCenterId: (item && item.costCenterId) ? item.costCenterId : '',
          projectId: (item && item.projectId) ? item.projectId : '',
          employeeId: '',
          employees: (item && item.employees) ? item.employees : [],
          registryDate: (item && item.time.registryDate) ? item.time.registryDate : '',
          timeRanges: (item && item.timeRanges) ? item.timeRanges : [{ from: '', to: ''}],
          status: (item && item.status) ? item.status : ''
        }
        this.showSavedForm = true
      },
      handlerSave() {
        this.saveBuilder(this.documentObj)
      },
      handlerHideEditionForm() {
        this.showEditionForm = false;
      },
      handlerShowEditionForm(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          costCenterId: (item && item.costCenterId) ? item.costCenterId : '',
          projectId: (item && item.projectId) ? item.projectId : '',
          info: item.info,
          employee: item.employee,
          registryDate: (item && item.time.rawRegistryDate) ? item.time.rawRegistryDate : '',
          timeRange: (item && item.time) ? { from: item.time.from, to: item.time.to } : {},
          timeRanges: [{ from: item.time.from, to: item.time.to }],
          status: (item && item.status) ? item.status : ''
        }
        this.showEditionForm = true;
      },
      handlerEditRegistry() {
        this.updateBuilder(this.documentObj);
      },
      handlerHideConfirmationDelete() {
        this.showConfirmationDelete = false
      },
      handlerShowConfirmationDelete(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          costCenterId: (item && item.costCenterId) ? item.costCenterId : '',
          projectId: (item && item.projectId) ? item.projectId : '',
          info: item.info,
          employee: item.employee,
          registryDate: (item && item.time.rawRegistryDate) ? item.time.rawRegistryDate : '',
          timeRange: (item && item.time) ? { from: item.time.from, to: item.time.to } : {},
          status: (item && item.status) ? item.status : ''
        }
        this.showConfirmationDelete = true
      },
      handlerDeleteRegistry() {
        this.handlerHideConfirmationDelete();
        this.deleteBuilder(this.documentObj.id);
      },
      handlerShowStatusChange(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          costCenterId: (item && item.costCenterId) ? item.costCenterId : '',
          projectId: (item && item.projectId) ? item.projectId : '',
          employeeId: '',
          employees: (item && item.employees) ? item.employees : [],
          registryDate: (item && item.rawRegistryDate) ? item.rawRegistryDate : '',
          status: (item && item.status) ? item.status : ''
        }
        this.showStatusChange = true
      },
      handlerHideStatusChange() {
        this.showStatusChange = false
      },
      handlerStatusChange(newStatus) {
        this.handlerHideStatusChange()
        if (newStatus === 'APPROVED') {
          this.markStatusAsApprovedBuilder(this.documentObj.id)
        } else if (newStatus === 'REJECTED') {
          this.markStatusAsRejectedBuilder(this.documentObj.id)
        }
      },
      validatePermissionTypeForCreatingANewRecord(currentModulePermission) {
        if (localStorage.periodOnlyReadMode && localStorage.periodOnlyReadMode !== undefined)
          return 'READ'

        return currentModulePermission;
      },

      consultBuilder() {
        this.hide = true
        const REQUEST_HOURS_REGISTRIES_ENDPOINT = 
          ['GR_WRITE', 'GENERAL_READ'].includes(this.$root.data.accessList.gestion.hoursRegistry.permission) ? '/hours-registry/manager/list' : '/hours-registry/manager/list/account-id'
        const requestBodyElement = {
          costCenterId: this.filters.costCenterId,
          projectId: this.filters.projectId,
          employeeId: this.filters.employeeId,
          status: this.filters.status,
          page: this.pagination.currentPage,
          limit: this.pagination.limit
        }
        if (localStorage.periodOnlyReadMode && localStorage.periodOnlyReadMode !== undefined) {
          requestBodyElement['selectedPeriodOnlyReadMode'] = localStorage.periodOnlyReadMode;
        }
        this.httpConsumerData = {
          endpoint: REQUEST_HOURS_REGISTRIES_ENDPOINT,
          method: 'post',
          withConfirmation: false,
          requestBody: requestBodyElement
        }
        this.sending = true
      },

      requestDownload() {
        this.hide = true
        const requestBodyElement = {
          costCenterId: this.filters.costCenterId,
          projectId: this.filters.projectId,
          employeeId: this.filters.employeeId,
          status: this.filters.status,
          page: this.download.currentPage,
          limit: this.download.limit
        }
        if (localStorage.periodOnlyReadMode && localStorage.periodOnlyReadMode !== undefined) {
          requestBodyElement['selectedPeriodOnlyReadMode'] = localStorage.periodOnlyReadMode;
        }
        console.log("requestBodyElement", JSON.stringify(requestBodyElement));
        this.httpConsumerData = {
          endpoint: '/hours-registry/manager/download',
          method: 'post',
          withConfirmation: false,
          requestBody: requestBodyElement
        }
        this.sending = true
      },

      saveBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/hours-registry/manager/',
          method: (item && item.id) ? 'put' : 'post',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            costCenterId: item.costCenterId,
            projectId: item.projectId,
            employees: item.employees,
            registryDate: item.registryDate,
            hoursRanges: item.timeRanges
          }
        }

        this.sending = true
      },

      updateBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: `/hours-registry/manager/${item.id}`,
          method: 'put',
          withConfirmation: true,
          requestBody: {
            costCenterId: item.costCenterId,
            projectId: item.projectId,
            registryDate: item.registryDate,
            hoursRange: {
              from: item.timeRanges[0].from,
              to: item.timeRanges[0].to
            }
          }
        }

        this.sending = true
      },

      deleteBuilder(id) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: `/hours-registry/manager/${id}`,
          method: 'delete',
          withConfirmation: true,
          requestBody: {}
        }

        this.sending = true
      },

      markStatusAsApprovedBuilder(documentId) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: `/hours-registry/manager/markAsApproved/${documentId}`,
          method: 'patch',
          withConfirmation: true,
          requestBody: {}
        }

        this.sending = true
      },

      markStatusAsRejectedBuilder(documentId) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: `/hours-registry/manager/markAsRejected/${documentId}`,
          method: 'patch',
          withConfirmation: true,
          requestBody: {}
        }

        this.sending = true
      },

      xlxsBuild(rows) {
        console.log("Rows count ready to be proccessed", rows.length)
        const sheetHeader = [
            [
                'Fecha',
                'Hora Entrada',
                'Hora Salida',
                'Código C.C.',
                'Nombre C.C.',
                'Código Proyecto',
                'Cliente Proyecto',
                'Nombre Proyecto',
                'Código Empleado',
                'ID. Empleado',
                'Nombre Empleado',
                'Estado',
            ]
        ]

        let Sheet = XLSX.utils.aoa_to_sheet(sheetHeader)

        for (const field of rows) {
            const sheetBody = [
                [
                    field.registryDate,
                    field.timeFrom,
                    field.timeTo,
                    field.costCenterCode,
                    field.costCenterName,
                    field.projectCode,
                    field.projectClient,
                    field.projectName,
                    field.employeeCode,
                    field.employeeIdNumber,
                    field.employeeFullname,
                    field.status
                ]
            ]
    
            XLSX.utils.sheet_add_aoa(Sheet,sheetBody, { origin: -1 });
        }

        this.download.building = false
        this.sending = false

        return XLSX.writeFile({
            SheetNames: ['Sheet'],
            Sheets: { Sheet }
        }, 'REGISTRO_HORAS_PERIODO_' + this.periodInfo + `.xlsx`)
      }

    }
}
</script>

<style>

</style>
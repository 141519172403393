<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body">
                                    <p class="color-rojo-fuerte"><b>Eliminar Registro de Hora</b></p>
                                    <!--<span class="color-gris-intermedio">¿Está seguro que quiere eliminar este registro de hora?</span>-->
                                    <div class="row" style="font-size: 85%; margin-top: -5px;">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <i class="fas fa-calendar-alt pr-1 color-gris-intermedio"></i>
                                                <span>{{item.registryDate}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <i class="fas fa-clock pr-1 color-verde-suave"></i>
                                                <span class="pr-2">{{item.timeRange.from}}</span>
                                                <i class="fas fa-clock pr-1 color-rojo-suave"></i>
                                                <span>{{item.timeRange.to}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="font-size: 85%; margin-top: -8px;">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <i class="fas fa-hand-holding-usd pr-1 color-azul-contundente"></i>
                                                <span>{{item.info.costCenter}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="font-size: 85%; margin-top: -8px;">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <i class="far fa-lightbulb pr-1 color-amarillo-fuerte"></i>
                                                <span>{{item.info.project}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="font-size: 85%; margin-top: -8px;">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <i class="fas fa-user pr-1 color-gris-normal"></i>
                                                <span>{{item.employee.fullname}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="font-size: 85%; margin-top: -8px;">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <i class="fas fa-id-card pr-1 color-gris-normal"></i>
                                                <span>{{item.employee.idNumber}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onDelete()" type="submit" class="btn btn-danger btn-sm">Eliminar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ConfirmationDelete',
    props: {
        item: Object,
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-hide-deletion-form')
        },
        onDelete() {
            this.$emit('handler-delete-registry')
        }
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
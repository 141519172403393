<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.generalParameters && $root.data.accessList.generalParameters.rules && $root.data.accessList.generalParameters.rules.active))">

      <div class="card shadow" style="margin-top: 85px!important;">

          <Headboard
            :title="'Parametrización Global'"
            :icon-reference="'fas fa-cogs'"
            :loading="sending"
            :minimalist="$root.data.minimalist"
            :permission="'READ'"
            :hide-filters="true"
            :customized-button="{
              text: 'Guardar Cambios',
              class: 'btn btn-success btn-sm',
              iconClass: 'fas fa-save',
              handler: 'handler-save',
              title: 'Guardar Cambios',
              visibility: true
            }"

            @handler-save="handlerSave"
            @handler-refresh="handlerRefresh"
          />

          <div class="card shadow mb-4">
            <div class="card-body py-2">
                <SettingsForm :settings="documentObj" />
            </div>
            <Loading v-if="sending" />
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import SettingsForm from './Forms/SettingsForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
import Loading from '../../../Widgets/Loading.vue'
export default {
    name: 'Rules',
    components: {
      Headboard,
      SettingsForm,
      ApiInteractionNotifier,
      Notification,
      Loading
    },
    data() {
      return {
        documentObj: {
          extraHours: {
            maximumAmountOfOvertime: 2,
            minimumMinutesToAddAnExtraHour: 59
          },
          shifts: {
            hoursOfLawPerShift: 8,
            graceMinutesOnEntry: 5,
            graceMinutesOnExit: 1,
            continuousWork: false
          },
          schedule: {
            diurnal: {
                from: '06:00',
                to: '21:00'
            },
            nocturnal: {
                from: '21:01',
                to: '05:59'
            }
          },
          calculation: {
            periodicity: 'MENSUAL',
            overtimeAdjustmentCalculation: false,
            bonusHours: false
          }
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        hide: false,
        sending: false
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.sending = false
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/all') > -1) {
          this.documentObj = callback.response.rules
        } else {
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.consultBuilder()
      },
      handlerSave() {
        this.saveBuilder(this.documentObj)
      },

      consultBuilder() {
        this.hide = true
        this.httpConsumerData = {
          endpoint: '/rules/manager/all',
          method: 'get',
          withConfirmation: false,
          requestBody: {}
        }
        this.sending = true
      },

      saveBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/rules/manager/',
          method: 'put',
          withConfirmation: true,
          requestBody: {
            extraHours: item.extraHours,
            shifts: item.shifts,
            schedule: item.schedule,
            calculation: item.calculation
          }
        }

        this.sending = true
      }

    }
}
</script>

<style>

</style>
<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-2">
                    <div class="form-group">
                        <span class="color-gris">Periodicidad</span>
                        <select v-model="settings.periodicity" class="form-control form-control-sm" required>
                            <option value='SEMANAL'>SEMANAL</option>
                            <option value='QUINCENAL'>QUINCENAL</option>
                            <option value='MENSUAL'>MENSUAL</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <div class="form-group">
                        <span class="color-gris">Cálculo +/-</span>
                        <div class="custom-control custom-switch mt-0">
                            <input v-model="settings.overtimeAdjustmentCalculation" class="custom-control-input" type="checkbox" value="" id="__overtimeAdjustmentCalculation">
                            <label class="custom-control-label" for="__overtimeAdjustmentCalculation"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <span class="color-gris">Horas bonos</span>
                        <div class="custom-control custom-switch mt-0">
                            <input v-model="settings.bonusHours" class="custom-control-input" type="checkbox" value="" id="__bonusHours">
                            <label class="custom-control-label" for="__bonusHours"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Calculation',
    props: {
        settings: {
            type: Object,
            require: true
        }
    }
}
</script>

<style>

</style>
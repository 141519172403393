<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg" style="margin-top: 15px;" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body">
                                    <p class="color-gris-intermedio mb-0">
                                        <b class="color-gris">Cargue de empleados</b>
                                    </p>
                                    <hr class="hr-customized">
                                    <span class="color-gris-intermedio justify-content-sm-between" style="font-size: 14px;"><b>Nota</b>: Recuerde que debe diligenciar correctamente el archivo Excel, exportarlo en extensión .CSV, con separador (punto y coma) &quot;<span class="color-rojo-intermedio"><b>;</b></span>&quot; y subirlo por este medio.</span>
                                    <br><br>
                                    <span class="color-gris-intermedio justify-content-sm-between" style="font-size: 14px;"><b>Recomendaciones</b></span>
                                    <ol class="color-gris-normal" style="margin-left: -15px; font-size: 13px;">
                                        <li>No borrar los campos que trae la cabecera por defecto.</li>
                                        <li>No cambiar el orden de las columnas.</li>
                                        <li>Debe diligenciar todos los campos.</li>
                                        <li>Para los campos de <u>área</u>, <u>grupo</u> y <u>cargo</u> debe colocar el código que corresponda.</li>
                                        <li>Debe colocar en el campo tipo de contrato los siguientes valores:</li>
                                        <ul>
                                            <li><b>FIJO</b>: Hace referencia al contrato a término fijo.</li>
                                            <li><b>INDEFINIDO</b>: Hace referencia al contrato a término indefinido.</li>
                                            <li><b>APRENDIZAJE</b>: Hace referencia al contrato de aprendizaje.</li>
                                            <li><b>TEMPORAL</b>: Hace referencia al contrato temporal, ocasional o accidental.</li>
                                        </ul>
                                    </ol>

                                    <a class="badge badge-primary text-white" style="cursor: pointer;" :href="$root.data.baseEndpoint + 'storage/files/cargue_empleados.xlsx'" download>
                                        <i class="far fa-file-excel" style="font-size: 18px;"></i> Descargar archivo base para diligenciar sus empleados
                                    </a>

                                    <hr class="hr-customized mt-3 mb-3">

                                    <div class="form-group">
                                        <span class="color-gris">Clic a continuación para subir el archivo <b>.csv</b>:</span> <span class="color-rojo-intermedio" style="cursor: context-menu;" title="Campo requerido">*</span>
                                        <input type="file" ref="fileCSV" class="form-control-file form-control-sm"  accept=".csv" @change="onFileLoad($event)">
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onUpload()" type="submit" class="btn btn-success btn-sm">
                                        <i class="fas fa-file-upload"></i> Cargar empleados
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
export default {
    name: 'MassiveUploadForm',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-visibility', false)
        },
        onUpload() {
            this.$emit('handler-massive-upload')
        },
        onFileLoad(e) {
            this.$emit('handler-file-load', {
                fileObject: this.$refs.fileCSV.files[0]
            })
        }
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
    .avatar-customized {
        max-width: 190px; 
        min-width: 190px; 
        max-height: 190px; 
        min-height: 190px; 
        object-fit: cover;
        border-radius: 10px; 
        box-shadow: #dfdfe8 -2px 1px 10px 2px;
    }
</style>
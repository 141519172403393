<template>
    <div v-show="(recordsCount > 0)" class="row pager-container">
        <div class="col-sm-1">
            <button v-if="excelDownloadActive"  class="btn btn-sm btn-outline-success ml-1 mr-1" style="height: 24px; font-size: 80%; margin-top: -2px; padding-top: 1.7px;" :title="(($root.data.minimalist) ? 'Descargar Excel': '')" @click="onExcelDownload()">
                <i class="fas fa-file-download"></i> <span v-if="!$root.data.minimalist">Descargar Excel</span>
            </button>
        </div>
        <div class="col-sm-4">
            <PeriodIndicatorWidget 
                v-if="periodIndicatorActive"
            />
        </div>
        <div class="col-sm-7 text-right">
            <span class="color-gris-intermedio" style="font-size: 80%; border: 1px solid lightgray; padding: 3px;"><b>{{$root.utils.thousandPointsFormatter(pagination.currentStart)}}</b>-<b>{{$root.utils.thousandPointsFormatter(pagination.currentEnd)}}</b> de <b>{{$root.utils.thousandPointsFormatter(pagination.total)}}</b></span>
            <button :disabled="(pagination.currentPage <= 1)" class="btn btn-sm ml-1 mr-1" :class="[{'btn-outline-primary': (pagination.currentPage > 1) }, {'btn-outline-secondary': (pagination.currentPage <= 1) }]" style="height: 24px; font-size: 80%; margin-top: -2px; padding-top: 1.7px;" @click="onPrevious()">
                <i class="fas fa-chevron-circle-left"></i> <span v-if="!$root.data.minimalist">Anterior</span>
            </button>
            <button :disabled="(pagination.currentPage >= pagination.totalPages)" class="btn btn-sm" :class="[{'btn-outline-primary': (pagination.currentPage < pagination.totalPages) }, {'btn-outline-secondary': (pagination.currentPage >= pagination.totalPages) }]" style="height: 24px; font-size: 80%; margin-top: -2px; padding-top: 1.7px;" @click="onNext()">
                <span v-if="!$root.data.minimalist">Siguiente</span> <i class="fas fa-chevron-circle-right"></i>
            </button>
        </div>
    </div>
    <!--<hr class="hr-customized" v-show="(recordsCount > 0)">-->
</template>

<script>
import PeriodIndicatorWidget from './Customized/PeriodIndicator.vue'

export default {
    name: 'Pager',
    components: {
        PeriodIndicatorWidget
    },
    props: {
        recordsCount: {
            type: Number,
            default: 0
        },
        pagination: {
            type: Object,
            default() {
                return {
                    currentStart: 0,
                    currentEnd: 0,
                    total: 0,
                    currentPage: 1,
                    totalPages: 1
                }
            }
        },
        excelDownloadActive: {
            type: Boolean,
            default: false
        },
        periodIndicatorActive: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onPrevious() {
            this.$emit('handler-previous-page')
        },
        onNext() {
            this.$emit('handler-next-page')
        },
        onExcelDownload() {
            this.$emit('handler-excel-donwload')
        }
    }
}
</script>

<style scoped>
.pager-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>
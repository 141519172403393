<template>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" :class="{'active': (selectedTab === 0) }" id="extraHours-tab" data-toggle="tab" href="#extraHours" role="tab" aria-controls="extraHours" :aria-selected="false" @click="setSelectedTab(0)">Horas extras</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :class="{'active': (selectedTab === 1) }" id="shifts-tab" data-toggle="tab" href="#shifts" role="tab" aria-controls="shifs" :aria-selected="true" @click="setSelectedTab(1)">Turnos</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :class="{'active': (selectedTab === 2) }" id="schedule-tab" data-toggle="tab" href="#schedule" role="tab" aria-controls="schedule" :aria-selected="true" @click="setSelectedTab(2)">Horario</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :class="{'active': (selectedTab === 3) }" id="calculation-tab" data-toggle="tab" href="#calculation" role="tab" aria-controls="calculation" :aria-selected="true" @click="setSelectedTab(3)">Cálculo de Liquidación</a>
        </li>
    </ul>

    <div class="tab-content" id="myTabContent">
        <div 
            class="tab-pane fade" 
            :class="[{'show': (selectedTab === 0) }, 
            {'active': (selectedTab === 0) }]" 
            id="extraHours" 
            role="tabpanel" 
            aria-labelledby="extraHours-tab">
            
            <ExtraHours :settings="settings.extraHours" />
        </div>
        <div 
            class="tab-pane fade" 
            :class="[{'show': (selectedTab === 1) }, 
            {'active': (selectedTab === 1) }]" 
            id="shifts" 
            role="tabpanel" 
            aria-labelledby="shifts-tab">
            
            <Shifts :settings="settings.shifts" />
        </div>
        <div 
            class="tab-pane fade" 
            :class="[{'show': (selectedTab === 2) }, 
            {'active': (selectedTab === 2) }]" 
            id="schedule" 
            role="tabpanel" 
            aria-labelledby="schedule-tab">
            
            <Schedule :settings="settings.schedule" />
        </div>
        <div 
            class="tab-pane fade" 
            :class="[{'show': (selectedTab === 3) }, 
            {'active': (selectedTab === 3) }]" 
            id="calculation" 
            role="tabpanel" 
            aria-labelledby="calculation-tab">
            
            <Calculation :settings="settings.calculation" />
        </div>
    </div>

</template>

<script>
import ExtraHours from './Tabs/ExtraHours.vue'
import Shifts from './Tabs/Shifts.vue'
import Schedule from './Tabs/Schedule.vue'
import Calculation from './Tabs/Calculation.vue';
export default {
    name: 'SettingsForm',
    components: {
        ExtraHours,
        Shifts,
        Schedule,
        Calculation
    },
    props: {
        settings: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedTab: 0
        }
    },
    methods: {
        setSelectedTab(value) {
            this.selectedTab = value
        }
    }

}
</script>

<style scoped>

</style>
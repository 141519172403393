<template>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <span class="color-gris">Empleados por seleccionar</span>
                <select v-model="item.employeeId" class="form-control form-control-sm" required @change="onAddEmployee($event)">
                    <option value=''>--Seleccione una opción--</option>
                    <option v-for="e in employees" :key="e._id" :value="e._id">{{e.fullname.toUpperCase()}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card bg-gray-200" v-for="(selectedEmployee, index) in selectedEmployees" :key="index">
                <div class="card-header pt-1 pb-1 pl-2">
                    <div class="row">
                        <div class="col-sm-11" style="font-size: 85%;">
                            <buttom class="btn btn-sm btn-danger mr-2" style="display: inline-flex; width: 35px; height: 25px;" @click="onRemoveEmployee(index)">
                                <i class="fas fa-minus-circle"></i>
                            </buttom>
                            <span>{{selectedEmployee.fullname.toUpperCase()}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HoursRegistryEmployeeGroupSavedForm',
    props: {
        item: {
            type: Object,
            required: true
        },
        employees: {
            type: Array,
            default() { return [] }
        },
        selectedEmployees: {
            type: Array,
            default() { return [] }
        },
    },
    methods: {
        onAddEmployee(event) {
            const value = event.target.value;
            if (value !== '') {
                this.$emit('handler-add-employee', value);
            }
        },
        onRemoveEmployee(index) {
            this.$emit('handler-remove-employee', index);
        }
    }
}
</script>

<style>

</style>
<template>
    <div class="card-header py-1">
        <div class="row">
            <div class="col-sm-6">
                <i class="icon-title" :class="iconReference"></i> <span class="special-title">{{title}}</span>
            </div>
            <div class="col-sm-6 text-right">
                <button :disabled="loading" class="btn btn-sm btn-outline-secondary" @click="onRefresh()" style="margin-right: 10px; cursor: pointer;">
                    <i class="fas fa-refresh" style="font-size: 15px;"></i> {{(minimalist) ? '' : 'Actualizar'}}
                </button>
                <button v-if="!hideFilters" :disabled="loading" class="btn btn-sm" :class="[{'btn-outline-success' : appliedFilters}, {'btn-outline-secondary' : !appliedFilters}]" :title="(appliedFilters ? 'Hay filtros aplicados' : 'Sin filtros')" @click="onShowFilter()" style="margin-right: 10px; cursor: pointer;">
                    <i class="fas fa-filter" style="font-size: 15px;"></i> {{(minimalist) ? '' : 'Filtros'}}
                </button>
                <button v-if="['WRITE', 'PR_WRITE', 'GR_WRITE'].includes(permission)" :disabled="loading" class="btn btn-success btn-sm" @click="onShowFormCreate()">
                    <i class="fas fa-plus-circle" style="font-size: 17px;"></i> {{(minimalist) ? '' : createActionName}}
                </button>
                <button v-if="customizedButton && customizedButton.visibility !== 'INVISIBLE'" :disabled="loading" :title="customizedButton.title" :class="customizedButton.class" :style="{'margin-left': (['WRITE', 'PR_WRITE', 'GR_WRITE'].includes(permission)) ? '10px' : '0px'}" style="cursor: pointer;" @click="onEmit(customizedButton.handler)">
                    <i :class="customizedButton.iconClass" style="font-size: 17px;"></i> {{(minimalist) ? '' : customizedButton.text}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Headboard',
    props: {
        iconReference: {
            type: String,
            default: 'fa-file'
        },
        title: String,
        loading: {
            type: Boolean,
            default: false
        },
        appliedFilters: {
            type: Boolean,
            default: false
        },
        minimalist: {
            type: Boolean,
            default: false
        },
        createActionName: {
            type: String,
            default: 'Crear'    
        },
        hideFilters: {
            type: Boolean,
            default: false
        },
        permission: {
            type: Boolean,
            default: 'READ'
        },
        customizedButton: {
            type: Object,
            default() {
                return null
            }
        }
    },
    methods: {
        onRefresh() {
            this.$emit('handler-refresh')
        },
        onShowFilter() {
            this.$emit('handler-show-filter', true)
        },
        onShowFormCreate() {
            this.$emit('handler-show-form-save')
        },
        onEmit(handler) {
            this.$emit(handler)
        }
    }
}
</script>

<style>

</style>
<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.collaborators && $root.data.accessList.collaborators.employees && $root.data.accessList.collaborators.employees.active))">

      <div class="card shadow" style="margin-top: 85px!important;">
        
          <Headboard 
            :title="'Administración de Empleados'"
            :icon-reference="'fas fa-hard-hat'"
            :loading="sending"
            :applied-filters="appliedFilters"
            :minimalist="$root.data.minimalist"
            :create-action-name="'Nuevo Empleado'"
            :permission="$root.data.accessList.collaborators.employees.permission"
            :customized-button="{
              text: 'Importar empleados',
              class: 'btn btn-outline-secondary btn-sm',
              iconClass: 'fas fa-upload',
              title: 'Importar empleados masivamente',
              handler: 'handler-show-massive-upload'
            }"
            @handler-refresh="handlerRefresh"
            @handler-show-filter="handlerShowFilter"
            @handler-show-form-save="handlerShowSavedForm"
            @handler-show-massive-upload="handlerShowMassiveUploadForm"
          />

          <div class="card-body container-pager-grid">
              <Pager 
                :pagination="pagination"
                :records-count="records.length"
                @handler-previous-page="handlerPreviousPage"
                @handler-next-page="handlerNextPage"
              />

              <CustomGrid
                :visible="!sending"
                :condensed="false"
                :titles="grid.titles"
                :mapping="grid.mapping"
                :records="records"
                :pagination="pagination"
                :permission="$root.data.accessList.collaborators.employees.permission"
                @handler-show-form-save="handlerShowSavedForm"
                @handler-show-status-change="handlerShowStatusChange"
                @handler-show-confirmation-delete="handlerShowConfirmationDelete"
              />              
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"
        :multipart="httpConsumerData.multipart"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <ConfirmationDelete :visible="showConfirmationDelete" :item="documentObj" @handler-hide="handlerHideConfirmationDelete" @handler-delete="handlerDelete" />
      <FilteredOutForm :visible="showFilters" :filters="filters" :cost-centers="costCenters" :areas="areas" :groups="groups" :positions="positions" @handler-show-filter="handlerShowFilter"  @handler-reset-filter="handlerResetFilter" @handler-apply-filter="handlerApplyFilter" />
      <SavedForm :visible="showSavedForm" :item="documentObj" :accountPhotoURL="accountPhotoURL" :cost-centers="costCenters" :areas="areas" :groups="groups" :positions="positions" @handler-visibility="handlerHideSavedForm" @handler-save="handlerSave" @handler-file-upload="handlerFileUpload" />
      <MassiveUploadForm :visible="showMassiveUploadForm" @handler-visibility="handlerHideMassiveUploadForm" @handler-file-load="handlerMassiveLoad" @handler-massive-upload="handlerMassiveUpload" />
      <StatusChangeForm :visible="showStatusChange" :item="documentObj" @handler-hide-status-change="handlerHideStatusChange" @handler-status-change="handlerStatusChange" />
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" :withError="withError" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import Pager from '../../../Widgets/Pager.vue'
import CustomGrid from '../../../Widgets/CustomGrid.vue'
import ConfirmationDelete from './Forms/ConfirmationDelete.vue'
import FilteredOutForm from './Forms/FilteredOutForm.vue'
import SavedForm from './Forms/SavedForm.vue'
import StatusChangeForm from './Forms/StatusChangeForm.vue'
import MassiveUploadForm from './Forms/MassiveUploadForm.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
import Config from '../../../../config'
export default {
    name: 'Employees',
    components: {
      Headboard,
      Pager,
      CustomGrid,
      ConfirmationDelete,
      FilteredOutForm,
      SavedForm,
      StatusChangeForm,
      MassiveUploadForm,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        areas: [],
        groups: [],
        positions: [],
        costCenters: [],
        records: [],
        documentObj: {
          id: '',
          code: '',
          idNumber: '',
          fullname: '',
          email: '',
          phoneNumber: '',
          address: '',
          areaId: '',
          groupId: '',
          positionId: '',
          costCenterId: '',
          contractType: '',
          photo: '',
          photoReference: '',
          status: ''
        },
        filters: {
          code: '',
          idNumber: '',
          fullname: '',
          areaId: '',
          groupId: '',
          positionId: '',
          contractType: '',
          status: ''
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {},
          multipart: false
        },
        confirmationMessage: '',
        withError: false,
        hide: false,
        sending: false,
        appliedFilters: false,
        showConfirmationDelete: false,
        showFilters: false,
        showSavedForm: false,
        showStatusChange: false,
        showMassiveUploadForm: false,
        pagination: {
          currentStart: 0,
          currentEnd: 0,
          total: 0,
          currentPage: 1,
          totalPage: 1,
          limit: 10
        },
        fileMassiveEmployees: '',
        fileAvatar: '',
        accountPhotoURL: '',
        grid: {
          titles: [{name: 'Foto'}, {name: 'Empleado'}, {name: 'Clasificación'}, {name: 'Estado', center: true}, {name: 'Acciones', center: true}],
          mapping: [
            {
              mode: 'IMAGE',
              ref: 'photo',
              style: {
                  option: 'border-radius: 10px; box-shadow: #dfdfe8 -2px 1px 0px 2px; object-fit: cover;',
                  width: 70,
                  height: 70
              }
            },
            {
              mode: 'MULTI_FIELDS',
              ref: 'employee',
              refs: [{
                title: 'Código',
                name: 'code',
              }, {
                title: 'Cédula',
                name: 'idNumber'
              }, {
                title: 'Nombre',
                name: 'fullname'
              }, {
                title: 'Teléfono',
                name: 'phoneNumber',
              }, {
                title: 'Email',
                name: 'email'
              }, {
                title: 'Dirección',
                name: 'address'
              }]
            },
            {
              mode: 'MULTI_FIELDS',
              ref: 'classification',
              refs: [{
                title: 'Área',
                name: 'area',
              }, {
                title: 'Grupo',
                name: 'group'
              }, {
                title: 'Cargo',
                name: 'position'
              }, {
                title: 'Ct. Costo',
                name: 'costCenter'
              }, {
                title: 'Contrato',
                name: 'contractType'
              }]
            },
            {
                mode: 'ONLY_ACTION',
                ref: 'status',
                tooltip: {
                    ACTIVO: 'Deshabilitar área',
                    INACTIVO: 'Habilitar área'
                },
                class: {
                    ACTIVO: 'fas fa-check-circle color-verde-intermedio',
                    INACTIVO: 'fas fa-ban color-rojo-intermedio'
                },
                handler: 'handler-show-status-change'
            },
            {
              mode: 'ACTIONS',
              center: false,
              actions: [
                {
                  type: 'EDIT',
                  tooltip: 'Editar',
                  handler: 'handler-show-form-save'
                },
                {
                  type: 'DELETE',
                  tooltip: 'Eliminar',
                  handler: 'handler-show-confirmation-delete'
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      getRecordsAfterUpdate(records) {
        records.forEach((v, i, arr) => {
          arr[i].photo = v.photo ? v.photo : Config.ICONS.EMPLOYEE_AVATAR
        })

        return records
      },
      handlerWebServiceResponse(callback) {
        this.sending = false
        this.withError = callback.error
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, ((callback.error) ? 8000 : 2500))
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.pagination.currentPage = callback.response.currentPage
          this.pagination.totalPages = callback.response.totalPages
          this.pagination.currentStart = ((this.pagination.limit * (this.pagination.currentPage - 1)) + 1)
          this.pagination.currentEnd = ((this.pagination.limit * (this.pagination.currentPage - 1)) + callback.response.records.length)
          this.pagination.total = callback.response.totalRecords
          this.records = this.getRecordsAfterUpdate(callback.response.records)
          this.areas = callback.response.areas
          this.groups = callback.response.groups
          this.positions = callback.response.positions
          this.costCenters = callback.response.costCenters
        } else {
          if (callback.error) {
            return
          }
          this.fileAvatar = ''
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.pagination.currentPage = 1
        this.consultBuilder()
      },
      handlerPreviousPage() {
        this.pagination.currentPage -= 1
        this.consultBuilder()
      },
      handlerNextPage() {
        this.pagination.currentPage += 1
        this.consultBuilder()
      },
      handlerShowFilter(v) {
        this.showFilters = v
      },
      handlerResetFilter() {
        this.filters = {
          code: '',
          name: '',
          status: ''
        }
        this.appliedFilters = false
        this.showFilters = false
        this.consultBuilder()
      },
      handlerApplyFilter() {
        this.appliedFilters = true
        this.showFilters = false
        this.consultBuilder()
      },
      handlerHideSavedForm() {
        this.showSavedForm = false
      },
      handlerShowSavedForm(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          code: (item && item.code) ? item.code : '',
          idNumber: (item && item.idNumber) ? item.idNumber : '',
          fullname: (item && item.fullname) ? item.fullname : '',
          email: (item && item.email) ? item.email : '',
          phoneNumber: (item && item.phoneNumber) ? item.phoneNumber : '',
          address: (item && item.address) ? item.address : '',
          areaId: (item && item.areaId) ? item.areaId : '',
          groupId: (item && item.groupId) ? item.groupId : '',
          positionId: (item && item.positionId) ? item.positionId : '',
          costCenterId: (item && item.costCenterId) ? item.costCenterId : '',
          contractType: (item && item.contractType) ? item.contractType : '',
          photo: (item && item.photo) ? item.photo : '',
          photoReference: (item && item.photoReference) ? item.photoReference : '',
          status: (item && item.status) ? item.status : ''
        }
        this.accountPhotoURL = (item && item.photo) ? item.photo : Config.ICONS.EMPLOYEE_AVATAR
        this.fileAvatar = ''
        this.showSavedForm = true
      },
      handlerSave() {
        if (this.fileAvatar) {
          if (this.fileAvatar.type.indexOf('image/') < 0) {
            alert("El formato del archivo debe ser una imagen")
            return
          } else if (this.fileAvatar.size === 0) {
            alert("El archivo debe tener un peso de 0 bytes")
            return
          } else if (this.fileAvatar.size > 2097152) {
            alert("El peso máximo permitido para la foto es de 2 MB")
            return
          }
        }
        this.handlerHideSavedForm()
        this.saveBuilder(this.documentObj)
      },
      handlerShowStatusChange(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          code: (item && item.code) ? item.code : '',
          fullname: (item && item.fullname) ? item.fullname : '',
          status: (item && item.status) ? item.status : ''
        }
        this.showStatusChange = true
      },
      handlerHideStatusChange() {
        this.showStatusChange = false
      },
      handlerStatusChange() {
        this.handlerHideStatusChange()
        this.statusChangeBuilder(this.documentObj)
      },
      handlerHideConfirmationDelete() {
        this.showConfirmationDelete = false
      },
      handlerShowConfirmationDelete(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          code: (item && item.code) ? item.code : '',
          fullname: (item && item.fullname) ? item.fullname : ''
        }
        this.showConfirmationDelete = true
      },
      handlerDelete() {
        this.handlerHideConfirmationDelete()
        this.deleteBuilder(this.documentObj.id)
      },
      handlerFileUpload(obj) {
        this.fileAvatar = obj.fileObject
        this.accountPhotoURL = obj.tempPath
      },
      handlerShowMassiveUploadForm() {
        this.showMassiveUploadForm = true
      },
      handlerHideMassiveUploadForm() {
        this.showMassiveUploadForm = false
      },
      handlerMassiveLoad(obj) {
        this.fileMassiveEmployees = obj.fileObject
      },
      handlerMassiveUpload() {
        if (!this.fileMassiveEmployees) {
            alert('No ha seleccionado ningún archivo')
            return
        } else if (!this.$root.utils.isMimeTypeCSV(this.fileMassiveEmployees.name)) {
            alert('El formato del archivo debe ser .csv')
            return
        } else if (this.fileMassiveEmployees.size === 0) {
            alert('El archivo tiene un peso de 0 bytes')
            return
        } else if (this.fileMassiveEmployees.size > 5242880) {
            alert('El peso máximo permitido es de 5 MB')
            return
        }
        this.handlerHideMassiveUploadForm()
        this.massiveSaveBuilder()
      },

      consultBuilder() {
        this.hide = true
        this.httpConsumerData = {
          endpoint: '/employee/manager/list',
          method: 'post',
          withConfirmation: false,
          multipart: false,
          requestBody: {
            code: this.filters.code,
            idNumber: this.filters.idNumber,
            fullname: this.filters.fullname,
            areaId: this.filters.areaId,
            groupId: this.filters.groupId,
            positionId: this.filters.positionId,
            contractType: this.filters.contractType,
            status: this.filters.status,
            page: this.pagination.currentPage,
            limit: this.pagination.limit
          }
        }
        this.sending = true
      },

      saveBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/employee/manager/',
          method: (item && item.id) ? 'put' : 'post',
          withConfirmation: true,
          multipart: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            code: (item && item.code) ? item.code : '',
            idNumber: (item && item.idNumber) ? item.idNumber : '',
            fullname: (item && item.fullname) ? item.fullname : '',
            email: (item && item.email) ? item.email : '',
            phoneNumber: (item && item.phoneNumber) ? item.phoneNumber : '',
            address: (item && item.address) ? item.address : '',
            areaId: (item && item.areaId) ? item.areaId : '',
            groupId: (item && item.groupId) ? item.groupId : '',
            positionId: (item && item.positionId) ? item.positionId : '',
            costCenterId: (item && item.costCenterId) ? item.costCenterId : '',
            contractType: (item && item.contractType) ? item.contractType : '',
            status: (item && item.status) ? item.status : '',
            photoURL: (item && item.photo) ? item.photo : '',
            photoReference: (item && item.photoReference) ? item.photoReference : '',
            employeePhoto: this.fileAvatar
          }
        }

        this.sending = true
      },

      massiveSaveBuilder() {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/employee/manager/massiveUpload',
          method: 'put',
          withConfirmation: true,
          multipart: true,
          requestBody: {
            employeesFile: this.fileMassiveEmployees
          }
        }

        this.sending = true
      },

      statusChangeBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/employee/manager/statusChange',
          method: 'put',
          withConfirmation: true,
          multipart: false,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            status: item.status
          }
        }

        this.sending = true
      },

      deleteBuilder(id) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/employee/manager',
          method: 'delete',
          withConfirmation: true,
          multipart: false,
          requestBody: {
            documentId: id
          }
        }

        this.sending = true
      }

    }
}
</script>

<style>

</style>
<template>
    <div id="wrapper">
        <Menu v-if="$root.data.accessList !== null" :access-settings="$root.data.accessList"></Menu>
        <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <TopBar @logout-action="logOut()" @companyModalAction="companyModalShow" @passwordModalAction="passwordModalShow" @avatarModalAction="avatarModalShow"></TopBar>
                <div class="container-fluid" style="position: static; width: 80%; height: auto; margin-top: 0px; margin-left: 20%; padding-left: 5px; padding-right: 5px;">
                    <router-view></router-view>
                </div>            
            </div>
        </div>

        <!-- Modal -->
        <div v-if="companyModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <p><b>Actualizar empresa</b></p><hr class="hr-customized">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">Nombre:</span> <span class="color-rojo-intermedio" style="cursor: context-menu;" title="Campo requerido">*</span>
                                                <input v-model="companyObject.name" type="text" class="form-control form-control-sm custom-input-group" placeholder="Escriba la razón social..." required>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <span class="color-gris">NIT:</span> <span class="color-rojo-intermedio" style="cursor: context-menu;" title="Campo requerido">*</span>
                                                <input v-model="companyObject.identification" type="number" class="form-control form-control-sm custom-input-group" placeholder="Escriba el NIT..." required min="0" @keydown="filterKeyNit">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="text-left">
                                                <img v-if="!companyObject.file" src="../assets/company.png" class="img-thumbnail" alt="..." style="max-width: 250px; min-width: 250px; max-height: 250px; min-height: 250px; object-fit: cover; background-image: linear-gradient(180deg, #ffffff 0, #d4fbff 25%, #9dd5f2 50%, #63b0d7 75%, #1e90c0 100%);">
                                                <img v-else :src="companyObject.file" class="img-thumbnail" alt="..." style="max-width: 250px; min-width: 250px; max-height: 250px; min-height: 250px; object-fit: cover;">
                                            </div>
                                            <div class="text-center mt-2">
                                                <input type="file" ref="fileCompany" class="form-control-file" accept="image/*" @change="handleCompanyFileUpload($event)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer" v-show="(errorMessage !== '')">
                                    <div class="alert alert-danger" style="padding: 5px; font-size: 13px;" role="alert">
                                        <i class="fas fa-exclamation-triangle"></i> {{errorMessage}}
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="companyModal = false; errorMessage = ''; fileCompany = '';">Cancelar</button>
                                    <button @click.prevent="companyChangeSave()" type="button" class="btn btn-success btn-sm">Guardar cambios</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- End Modal -->

        <!-- Modal -->
        <div v-if="passwordModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <p><b>Actualizar contraseña</b></p>
                                    <hr>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <span>Nueva contraseña:</span>
                                                <input type="password" v-model="password" class="form-control form-control-sm custom-input-group" placeholder="Escriba la nueva contraseña">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <span>Confirmar contraseña:</span>
                                                <input type="password" v-model="confirmationPassword" class="form-control form-control-sm custom-input-group" placeholder="Escribala nuevamente">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer" v-show="(errorMessage !== '')">
                                    <div class="alert alert-danger" style="padding: 5px; font-size: 13px;" role="alert">
                                        <i class="fas fa-exclamation-triangle"></i> {{errorMessage}}
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="passwordModal = false; errorMessage = ''; fileAvatar = '';">Cancelar</button>
                                    <button @click.prevent="passwordChangeSave()" type="button" class="btn btn-success btn-sm">Guardar cambios</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- End Modal -->

        <!-- Modal -->
        <div v-if="avatarModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <p><b>Actualizar foto</b></p><hr>
                                    <div class="text-center">
                                        <img v-if="!accountPhotoURL" :src="getImageUrl('user-avatar.png')" class="img-thumbnail" alt="..." style="max-width: 250px; min-width: 250px; max-height: 250px; min-height: 250px; object-fit: cover; background-image: radial-gradient(circle at 100% 0%, #00ffff 0, #00ebff 25%, #0cc1ed 50%, #3499bf 75%, #3a7795 100%);" >
                                        <img v-else :src="accountPhotoURL" class="img-thumbnail" alt="..." style="max-width: 250px; min-width: 250px; max-height: 250px; min-height: 250px; object-fit: cover;" >
                                    </div>
                                    <div class="text-center mt-2">
                                        <input type="file" ref="fileAvatar" class="form-control-file" accept="image/*" @change="handleFileUpload($event)">
                                    </div>
                                </div>
                                <div class="modal-footer" v-show="(errorMessage !== '')">
                                    <div class="alert alert-danger" style="padding: 5px; font-size: 13px;" role="alert">
                                        <i class="fas fa-exclamation-triangle"></i> {{errorMessage}}
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="avatarModal = false; errorMessage = ''; fileAvatar = '';">Cancelar</button>
                                    <button @click.prevent="avatarChangeSave()" type="button" class="btn btn-success btn-sm">Guardar cambios</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- End Modal -->

        <!-- Modal -->
          <div v-if="(sending || confirmed)">
                <transition name="modal">
                    <div class="modal-mask2">
                        <div class="modal-wrapper2">
                            <div class="modal-dialog" role="document">
                                <div class="row justify-content-center">
                                    <div v-show="sending">
                                        <i class="fa fa-spinner girar color-white" style="font-size: 200%"></i> <span class="color-white" style="font-size: 22px; font-weight: bold; margin-left: 5px;">Por favor espere...</span>
                                    </div>
                                    <div v-show="confirmed">
                                        <div class="alert alert-success" role="alert">
                                            <i class="fas fa-check-circle color-verde-intermedio" style="font-size: 20px;"></i> <span class="color-verde-intermedio" style="font-size: 20px; font-weight: normal; margin-left: 5px;">{{confirmationMessage}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
          </div>

    </div>
</template>

<script>
import Menu from './Navigation/Menu/index.vue'
import TopBar from './Navigation/TopBar/index.vue'

export default {
    name: 'Principal',
    components: {
        Menu,
        TopBar
    },
    data() {
        return {
            password: '',
            confirmationPassword: '',
            fileAvatar: '',
            fileCompany: '',
            accountPhotoURL: '',
            companyObject: {
                file: '',
                name: '',
                identification: ''
            },
            companyModal: false,
            passwordModal: false,
            avatarModal: false,
            sending: false,
            confirmed: false,
            confirmationMessage: '',
            errorMessage: ''
        }
    },
    methods: {
        handleFileUpload(e) {
            this.fileAvatar = this.$refs.fileAvatar.files[0]
            var tempPath = URL.createObjectURL(e.target.files[0])
            this.accountPhotoURL = tempPath
            this.errorMessage = ''
        },
        handleCompanyFileUpload(e) {
            this.fileCompany = this.$refs.fileCompany.files[0]
            var tempPath = URL.createObjectURL(e.target.files[0])
            this.companyObject.file = tempPath
            this.errorMessage = ''
        },
        logOut() {
            this.$root.logOut()
        },
        companyModalShow() {
            this.companyObject = {
                file: (this.$root.data.company.logo) ? this.$root.data.company.logo : '',
                name: (this.$root.data.company.name) ? this.$root.data.company.name : '',
                identification: (this.$root.data.company.nit) ? this.$root.data.company.nit : ''
            }
            this.companyModal = true
        },
        companyChangeSave() {
            this.errorMessage = ''
            if (!this.companyObject.name) {
                this.errorMessage = 'Debe escribir la razón social o nombre de la empresa'
                return
            } else if (!this.companyObject.identification) {
                this.errorMessage = 'Debe escribir el NIT de la empresa'
                return
            } else if (this.fileCompany) {
                if (this.fileCompany.type.indexOf('image/') < 0) {
                    this.errorMessage = 'El formato del archivo debe ser una imagen'
                    return
                } else if (this.fileCompany.size === 0) {
                    this.errorMessage = 'El archivo tiene un peso de 0 bytes'
                    return
                } else if (this.fileCompany.size > 2097152) {
                    this.errorMessage = 'El peso máximo permitido es de 2 MB'
                    return
                }
            }

            this.sending = true
            let formData = new FormData()
            if (this.fileCompany) {
                formData.append('logo', this.fileCompany)
            }
            formData.append('name', this.companyObject.name)
            formData.append('nit', this.companyObject.identification)
            this.$root.data.axios.put('/company/manager', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                this.confirmationMessage = 'La información de la empresa ha sido actualizada'
                this.sending = false
                this.confirmed = true
                this.companyModal = false
                this.$root.data.company.name = localStorage.companyName = response.data.company.name
                this.$root.data.company.nit = localStorage.companyNit = response.data.company.nit
                this.$root.data.company.logo = localStorage.logo = response.data.company.logoURL
                setTimeout(() => {
                    this.confirmed = false
                    this.fileCompany = ''
                }, 1500)
            }).catch(err => {
                console.error(err)
                this.sending = false
                if (err.response.data.auth === false) {
                    alert('La sesión ha caducado')
                    this.$root.data.token = ''
                    localStorage.token = ''
                }
            })
        },
        passwordModalShow() {
            this.password = ''
            this.confirmationPassword = ''
            this.passwordModal = true
        },
        passwordChangeSave() {
            this.errorMessage = ''
            if (!this.password || !this.confirmationPassword) {
                this.errorMessage = 'Debe ingresar la nueva contraseña y confirmarla'
                return
            } else if (this.password !== this.confirmationPassword) {
                this.errorMessage = 'Las contraseñas no coinciden'
                return
            }

            this.sending = true
            this.$root.data.axios.put('/account/manager/passwordChange', {
                password: this.password
            }).then((response) => {
                this.confirmationMessage = response.data.message
                this.sending = false
                this.confirmed = true
                this.passwordModal = false
                setTimeout(() => {
                    this.confirmed = false
                }, 1500)
            }).catch(err => {
                console.error(err)
                this.sending = false
                if (err.response.data.auth === false) {
                    alert('La sesión ha caducado')
                    this.$root.data.token = ''
                    localStorage.token = ''
                }
            })
        },
        avatarModalShow() {
            this.accountPhotoURL = this.$root.data.photo
            this.avatarModal = true
        },
        avatarChangeSave() {
            this.errorMessage = ''
            if (!this.fileAvatar) {
                this.errorMessage = 'No ha seleccionado ningún archivo'
                return
            } else if (this.fileAvatar.type.indexOf('image/') < 0) {
                this.errorMessage = 'El formato del archivo debe ser una imagen'
                return
            } else if (this.fileAvatar.size === 0) {
                this.errorMessage = 'El archivo tiene un peso de 0 bytes'
                return
            } else if (this.fileAvatar.size > 2097152) {
                this.errorMessage = 'El peso máximo permitido es de 2 MB'
                return
            }

            this.sending = true
            let formData = new FormData()
            formData.append('avatar', this.fileAvatar)
            this.$root.data.axios.put('/account/manager/profilePhotoUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                this.confirmationMessage = 'La foto de perfil ha sido actualizada'
                this.sending = false
                this.confirmed = true
                this.avatarModal = false
                this.$root.data.photo = localStorage.photo = response.data.photoPath
                setTimeout(() => {
                    this.confirmed = false
                    this.fileAvatar = ''
                }, 1500)
            }).catch(err => {
                console.error(err)
                this.sending = false
                //let message = (err.response.data) ? err.response.data.message : ''
                if (err.response.data.auth === false) {
                    alert('La sesión ha caducado')
                    this.$root.data.token = ''
                    localStorage.token = ''
                }
            })
        },
        filterKeyNit(e) {
            const key = e.key
            if (['0','1','2','3','4','5','6','7','8','9'].indexOf(key) > -1 && this.companyObject.identification.length >= 10) {
                return e.preventDefault()
            }
            if (key === '.' || key === 'e' || key === ',' || key === '-' || key === '+' || key === '_' || key === ':') {
                return e.preventDefault()
            }
        },
        getImageUrl(imageName) {
            let images = require.context('../assets/menu/', false, /\.png$/);
            return images('./' + imageName);
        },
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: grid;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-mask2 {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-wrapper2 {
        display: table-cell;
        vertical-align: middle;
    }
</style>
const DateFormat = require('dateformat')
const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

module.exports = {
    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    },
    setFormatDate(timestamp, withTime = true, withFullTime = false) {
        try {
            let date;
            if (timestamp) {
                date = new Date(timestamp)
                let format = (withTime) ? ((withFullTime) ? 'dd/mm/yyyy HH:MM:ss' : 'dd/mm/yyyy HH:MM') : 'dd/mm/yyyy'
                return DateFormat(date, format)
            }
            return ''
        } catch (e) {
            return ''
        }
    },
    setUpFormatDate(val) {
        if (val) {
            let d = val.substr(8, 2)
            let m = val.substr(5, 2)
            let y = val.substr(0, 4)
            return `${d}/${m}/${y}`
        }
        return ''
    },
    showDateFormatMoreHuman(dateStr) {
        const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
        const month = parseInt(dateStr.substring(5,7))-1;
        const day = dateStr.substring(8,10);

        return `${months[month]}/${day}`
    },
    getYearFromDateStr(dateStr) {
        return dateStr.substring(0,4);
    },
    validateEmail(email) {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    },
    thousandPointsFormatter(x) {
        if (x === undefined) return
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    showFormatFriendlyDate(s) {
        let d = new Date(s + ' 00:00')
        return d.getDate() + ' ' + months[d.getMonth()]
    },
    isSunday(s) {
        let d = new Date(s + ' 00:00')
        return d.getDay() === 0
    },
    isMimeTypeCSV(filename) {
        let parts = filename.toLowerCase().split(".")
        return (parts[1] === "csv")
    }
}
<template>
  <div class="container">

    <!-- Outer Row -->
    <div class="row justify-content-center">

      <div class="col-xl-4 col-lg-5 col-md-9 my-4">

        <div class="card o-hidden border-0 my-5 recuadro login-container" style="-webkit-box-shadow: 8px 8px 24px 0px rgba(145, 146, 168, 1);-moz-box-shadow: 8px 8px 24px 0px rgba(145, 146, 168, 1);box-shadow: 8px 8px 24px 0px rgba(145, 146, 168, 1); margin-bottom: 24vh !important;">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col-lg-12" style="background: #273370;">
                <div class="pt-0 pl-5 pr-5 pb-0">
                  <div class="text-center mt-3">
                    <img :src="getLogoImgUrl()" width="200" alt="Logo">
                  </div>
                  <div class="my-4">
                      <form class="user">
                        <div class="form-group">
                            <input autocomplete="off" :disabled="(sending)" v-model="username" type="text" class="form-control" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Usuario..." autofocus required>
                        </div>
                        <div class="form-group" style="margin-bottom: 35px;">
                            <input autocomplete="off" :disabled="(sending)" v-model="password" type="password" class="form-control" id="exampleInputPassword" placeholder="Contraseña..." required>
                        </div>
                        <button type="submit" @click="signIn()" :disabled="(sending)" class="btn btn-block mt-4" style="background-color: #c31d6b; color: white; font-weight: bold;">
                          <i v-show="sending" class="fa fa-spinner girar" style="font-size: 150%"></i> INICIAR SESIÓN
                        </button>
                        <hr>
                        <div v-show="(errorMessage !== '')" class="alert alert-danger" role="alert" style="font-size: 90%;">
                          <i class="fas fa-exclamation-triangle"></i> {{errorMessage}}
                        </div>
                        <div v-show="$root.data.expiredSession" class="alert alert-warning" role="alert" style="font-size: 90%;">
                          <i class="fas fa-exclamation-triangle"></i> Su sesión ha caducado
                        </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="footer">
      <span>
        Powered by
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="50" viewBox="0 0 232 50" fill="none">
        <path d="M44.5819 33.7815L29.7 0H21.8361L0 50C5.41813 50 10.3096 46.7683 12.4322 41.785L14.0321 38.0346L14.044 38.0147L41.813 38.0346C42.7785 38.0346 43.6004 37.5997 44.139 36.9414C44.5061 36.5026 44.7455 35.9679 44.8173 35.3934C44.8891 34.8707 44.8173 34.3201 44.5859 33.7855M17.2319 30.2865L21.8401 19.538L25.7421 10.4373L29.4486 19.0073L34.3281 30.2905H17.2319V30.2865Z" fill="white"></path>
        <path d="M81.5312 19.0113C81.5312 28.9738 73.8748 37.1489 64.1238 37.9668C64.0041 37.9708 63.8804 37.9828 63.7488 37.9947C63.3378 38.0227 62.9308 38.0306 62.5159 38.0306H55.067L50.6343 48.1288C50.1316 49.2659 49.0065 50 47.7657 50H44.8172C43.5724 50 42.4473 49.2619 41.9526 48.1248L38.645 40.5362L45.3279 40.5522L48.7591 32.8479C49.4533 31.2879 50.9974 30.2865 52.705 30.2865H62.4202C68.5764 30.2865 73.7751 25.4389 73.9187 19.2826C74.0703 12.8711 68.8956 7.60054 62.5199 7.60054H51.0533L54.3728 0H62.5159C67.7665 0 72.5183 2.13055 75.9575 5.57373C79.4007 9.00894 81.5312 13.7648 81.5312 19.0153" fill="white"></path>
        <path d="M98.1968 20.735V32.6165H101.548V17.3835C99.7009 17.3835 98.1968 18.8877 98.1968 20.735Z" fill="white"></path>
        <path d="M115.584 20.695V26.7634L108.447 17.3875H105.354V32.6205H108.666V22.9372L116.043 32.6205H118.892V17.3875C117.065 17.3875 115.588 18.8717 115.588 20.699" fill="white"></path>
        <path d="M129.768 23.847V26.7436H133.139V28.9339C132.281 29.5444 131.232 29.8556 129.983 29.8556C129.301 29.8556 128.683 29.7359 128.128 29.4925C127.566 29.2531 127.083 28.914 126.672 28.4831C126.257 28.0442 125.942 27.5335 125.71 26.943C125.487 26.3565 125.375 25.7102 125.375 25.012V24.9681C125.375 24.3178 125.483 23.7033 125.71 23.1248C125.938 22.5543 126.249 22.0476 126.648 21.6207C127.051 21.1938 127.514 20.8506 128.04 20.5953C128.567 20.34 129.141 20.2123 129.748 20.2123C130.187 20.2123 130.586 20.2522 130.949 20.32C131.312 20.3958 131.647 20.4956 131.958 20.6232C132.273 20.7589 132.569 20.9185 132.86 21.106C133.155 21.2895 133.442 21.509 133.73 21.7603L135.84 19.2108C135.445 18.8757 135.042 18.5844 134.619 18.3211C134.201 18.0618 133.754 17.8423 133.271 17.6708C132.792 17.4952 132.277 17.3636 131.719 17.2678C131.16 17.1721 130.538 17.1282 129.856 17.1282C128.695 17.1282 127.629 17.3317 126.66 17.7466C125.686 18.1615 124.844 18.7281 124.138 19.4422C123.428 20.1604 122.87 20.9943 122.471 21.9438C122.072 22.8934 121.872 23.9108 121.872 25V25.0439C121.872 26.173 122.068 27.2183 122.459 28.1759C122.854 29.1374 123.4 29.9633 124.102 30.6535C124.805 31.3518 125.646 31.8984 126.628 32.2854C127.609 32.6764 128.695 32.8719 129.88 32.8719C131.272 32.8719 132.513 32.6365 133.594 32.1657C134.675 31.6989 135.605 31.1243 136.387 30.458V23.843H129.776L129.768 23.847Z" fill="white"></path>
        <path d="M142.91 29.6322V26.4404H150.088V23.456H142.91V20.3639H151.069V17.3835H139.599V32.6165H151.177V29.6322H142.91Z" fill="white"></path>
        <path d="M164.403 20.695V26.7634L157.269 17.3875H154.177V32.6205H157.481V22.9372L164.858 32.6205H167.711V17.3875C165.883 17.3875 164.399 18.8717 164.399 20.699" fill="white"></path>
        <path d="M171.521 20.735V32.6165H174.872V17.3835C173.021 17.3835 171.521 18.8877 171.521 20.735Z" fill="white"></path>
        <path d="M181.982 29.6322V26.4404H189.164V23.456H181.982V20.3639H190.141V17.3835H178.679V32.6165H190.249V29.6322H181.982Z" fill="white"></path>
        <path d="M202.693 27.1745C203.671 26.8114 204.457 26.2449 205.059 25.4669C205.658 24.6888 205.961 23.6834 205.961 22.4546V22.4107C205.961 20.8267 205.458 19.5979 204.449 18.7121C203.443 17.8264 202.031 17.3835 200.219 17.3835H193.257V32.6126H196.609V27.741H199.242L202.506 32.6126H206.424L202.701 27.1705L202.693 27.1745ZM202.565 22.6301C202.565 23.2804 202.346 23.8031 201.903 24.1941C201.46 24.5891 200.822 24.7806 199.996 24.7806H196.601V20.4118H199.932C200.758 20.4118 201.404 20.5913 201.867 20.9544C202.33 21.3175 202.565 21.8601 202.565 22.5862V22.6301Z" fill="white"></path>
        <path d="M209.137 20.735V32.6165H212.488V17.3835C210.637 17.3835 209.137 18.8877 209.137 20.735Z" fill="white"></path>
        <path d="M224.605 17.2759H221.513L214.986 32.6166H218.401L219.794 29.2014H226.233L227.621 32.6166H231.125L224.597 17.2759H224.605ZM220.99 26.2409L223.017 21.3016L225.04 26.2409H220.994H220.99Z" fill="white"></path>
      </svg>
      © {{ (new Date()).getFullYear() }}</span>
    </div>

  </div>
</template>

<script>
import Config from '../config'
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      sending: false,
      errorMessage: ''
    }
  },
  methods: {
    getPortadaImgUrl() {
      let images = require.context('../assets/', false, /\.png$/)
      return images('./portada.png')
    },
    getLogoImgUrl() {
      let images = require.context('../assets/', false, /\.png$/)
      return images('./logo__main.png')
    },
    signIn() {
      this.errorMessage = ''
      this.$root.data.expiredSession = false
      this.sending = true
      this.$root.data.axios.post('/account/signIn', {
        username: this.username,
        password: this.password
      }).then((response) => {
        this.confirm(true, response.data)
      }).catch((err) => {
        console.log("Error", err)
        this.confirm(false, null, err.response.data.message)
      })
    },
    confirm(wasSuccess, data, message = null) {
      this.sending = false
      if (wasSuccess) {
        if (!data.auth && !data.token) {
          this.errorMessage = data.message
          return
        }
        this.$root.data.fullname = data.fullname
        this.$root.data.role = data.role
        this.$root.data.accessList = data.accessSettings
        this.$root.data.minimalist = data.minimalist
        this.$root.data.photo = (data.photo) ? data.photo : Config.ICONS.USER_ACCOUNT
        this.$root.data.company.logo = (data.company && data.company.logo) ? data.company.logo : ''
        this.$root.data.company.name = (data.company && data.company.name) ? data.company.name : ''
        this.$root.data.company.nit = (data.company && data.company.nit) ? data.company.nit : ''

        localStorage.fullname = data.fullname
        localStorage.role = data.role
        localStorage.accessList = JSON.stringify(data.accessSettings)
        localStorage.minimalist = data.minimalist
        localStorage.photo = (data.photo) ? data.photo : Config.ICONS.USER_ACCOUNT
        localStorage.logo = (data.company && data.company.logo) ? data.company.logo : ''
        localStorage.companyName = (data.company && data.company.name) ? data.company.name : ''
        localStorage.companyNit = (data.company && data.company.nit) ? data.company.nit : ''
        if (data.currentPeriod)
          localStorage.currentPeriodInfo = JSON.stringify(data.currentPeriod)

        this.$root.setToken(data.token)
        this.$root.restartTimer()
      } else {
        this.errorMessage = message ? message : Config.MESSAGES.TRY_AGAIN_CONNECTION_ANY_ENDPOINT
      }
    }
  }
}
</script>

<style>

</style>
<template>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <span class="color-gris">Centro de costo</span>
                <select v-model="item.costCenterId" class="form-control form-control-sm" required>
                    <option value=''>--Seleccione una opción--</option>
                    <option v-for="c in costCenters" :key="c._id" :value="c._id">{{c.code}} {{c.name.toUpperCase()}}</option>
                </select>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <span class="color-gris">Proyecto</span>
                <select v-model="item.projectId" class="form-control form-control-sm" required>
                    <option value=''>--Seleccione una opción--</option>
                    <option v-for="p in projects" :key="p._id" :value="p._id">{{getProjectInfo(p)}}</option>
                </select>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    name: 'HoursRegistryClassificationSavedForm',
    props: {
        item: {
            type: Object,
            required: true
        },
        costCenters: {
            type: Array,
            default() { return [] }
        },
        projects: {
            type: Array,
            default() { return [] }
        },
    },
    methods: {
        getProjectInfo(p) {
            let info = `${p.code}`;
            info += ` ${p.client.substr(0,14)}`
            info += (p.client.length > 14) ? '...' : ''
            info += ` - ${p.name.substr(0,10)}`
            info += (p.name.length > 10) ? '...' : ''

            return info.toUpperCase();
        },
    }
}
</script>
<template>
    <!-- Horas normales -->
    <div class="row">
        <div class="col-2 card my-1 mx-3 px-0" v-for="(hourType, i) in hourTypes.slice(0,2)" :key="i" @dblclick="onToggleEdition(hourType)">
            <div class="card-header py-1 px-1" :class="hourType.style.background" onselectstart='return false;'>
                <div class="form-group mb-0" :class="hourType.style.font">
                    <div class="row">
                        <div class="col-6">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableType" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.type}}</b></span>
                        </div>
                        <div class="col-6 text-right">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableCode" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.code}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body py-2 px-1" onselectstart='return false;'>
                <textarea v-if="hourType.isEditable" v-model="hourType.editableDescription" class="form-control-sm custom-input-group px-1" style="max-width: 100%; min-width: 100%; height: 60px;" rows="2"></textarea>
                <p v-else class="text-justify1 color-gris-intermedio mb-0" style="font-size: 85%;">{{hourType.description}}</p>
            </div>
        </div>
    </div>
    <!-- Horas recargos nocturnos -->
    <div class="row">
        <div class="col-2 card my-1 mx-3 px-0" v-for="(hourType, i) in hourTypes.slice(2, 4)" :key="i" @dblclick="onToggleEdition(hourType)">
            <div class="card-header py-1 px-1" :class="hourType.style.background" onselectstart='return false;'>
                <div class="form-group mb-0" :class="hourType.style.font">
                    <div class="row">
                        <div class="col-6">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableType" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.type}}</b></span>
                        </div>
                        <div class="col-6 text-right">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableCode" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.code}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body py-2 px-1" onselectstart='return false;'>
                <textarea v-if="hourType.isEditable" v-model="hourType.editableDescription" class="form-control-sm custom-input-group px-1" style="max-width: 100%; min-width: 100%; height: 60px;" rows="2"></textarea>
                <p v-else class="text-justify1 color-gris-intermedio mb-0" style="font-size: 85%;">{{hourType.description}}</p>
            </div>
        </div>
    </div>
    <!-- Horas extras -->
    <div class="row">
        <div class="col-2 card my-1 mx-3 px-0" v-for="(hourType, i) in hourTypes.slice(4, 8)" :key="i" @dblclick="onToggleEdition(hourType)">
            <div class="card-header py-1 px-1" :class="hourType.style.background" onselectstart='return false;'>
                <div class="form-group mb-0" :class="hourType.style.font">
                    <div class="row">
                        <div class="col-6">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableType" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.type}}</b></span>
                        </div>
                        <div class="col-6 text-right">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableCode" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.code}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body py-2 px-1" onselectstart='return false;'>
                <textarea v-if="hourType.isEditable" v-model="hourType.editableDescription" class="form-control-sm custom-input-group px-1" style="max-width: 100%; min-width: 100%; height: 60px;" rows="2"></textarea>
                <p v-else class="text-justify1 color-gris-intermedio mb-0" style="font-size: 85%;">{{hourType.description}}</p>
            </div>
        </div>
    </div>
    <!-- Horas Bonos -->
    <div class="row">
        <div class="col-2 card my-1 mx-3 px-0" v-for="(hourType, i) in hourTypes.slice(8, 12)" :key="i" @dblclick="onToggleEdition(hourType)">
            <div class="card-header py-1 px-1" :class="hourType.style.background" onselectstart='return false;'>
                <div class="form-group mb-0" :class="hourType.style.font">
                    <div class="row">
                        <div class="col-6">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableType" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.type}}</b></span>
                        </div>
                        <div class="col-6 text-right">
                            <input v-if="hourType.isEditable" type="text" v-model="hourType.editableCode" class="form-control form-control-sm custom-input-group">
                            <span v-else class="ml-1"><b>{{hourType.code}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body py-2 px-1" onselectstart='return false;'>
                <textarea v-if="hourType.isEditable" v-model="hourType.editableDescription" class="form-control-sm custom-input-group px-1" style="max-width: 100%; min-width: 100%; height: 60px;" rows="2"></textarea>
                <p v-else class="text-justify1 color-gris-intermedio mb-0" style="font-size: 85%;">{{hourType.description}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HourTypesSavedForm',
    props: {
        hourTypes: {
            type: Array,
            required: true
        }
    },
    methods: {
        onToggleEdition(obj) {
            this.$emit('handler-toggle-edition', obj)
        }
    }
    
}
</script>

<style scoped>
    
</style>
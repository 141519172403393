<template>
  <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body">
                                    <p>
                                        <i v-if="item.status === 'ACTIVO'" class="fas fa-ban color-rojo-fuerte"></i>
                                        <i v-else class="fas fa-check-circle color-verde-fuerte"></i>
                                        <b :class="[{'color-rojo-fuerte': (item.status === 'ACTIVO')}, {'color-verde-fuerte': (item.status === 'INACTIVO')}]">{{(item.status === 'ACTIVO') ? ' Deshabilitar' : ' Habilitar'}} Centro de Costo</b>
                                        ({{item.code}})
                                    </p>
                                        
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onHide()">Cancelar</button>
                                    <button @click.prevent="onStatusChange()" type="submit" class="btn btn-success btn-sm" :class="{'btn-danger': (item.status === 'ACTIVO')}" >{{item.status === 'ACTIVO' ? 'Deshabilitar' : 'Habilitar'}}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'CostCenterStatusChangeForm',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        onHide() {
            this.$emit('handler-hide-status-change')
        },
        onStatusChange() {
            this.$emit('handler-status-change')
        }
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Cantidad máxima de horas extra por turno</span>
                        <input v-model="settings.maximumAmountOfOvertime" type="number" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <span class="color-gris">Minutos mínimos para sumar una hora extra</span>
                        <input v-model="settings.minimumMinutesToAddAnExtraHour" type="number" class="form-control form-control-sm custom-input-group" placeholder="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExtraHours',
    props: {
        settings: {
            type: Object,
            require: true
        }
    }
}
</script>

<style>

</style>
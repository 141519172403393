<template>
  <div v-if="($root.data.role === 'MASTER' ||
        ($root.data.accessList && $root.data.accessList.generalParameters && $root.data.accessList.generalParameters.holidays && $root.data.accessList.generalParameters.holidays.active))">

      <div class="card shadow" style="margin-top: 85px!important;">
        
          <Headboard 
            :title="'Configuración de Días Festivos'"
            :icon-reference="'far fa-calendar-alt'"
            :loading="sending"
            :hide-filters="true"
            :minimalist="$root.data.minimalist"
            :create-action-name="'Nuevo Festivo'"
            :permission="$root.data.accessList.generalParameters.holidays.permission"
            :customized-button="{
              text: 'Mostrar Calendario',
              class: 'btn btn-outline-secondary btn-sm',
              iconClass: 'far fa-calendar-plus',
              handler: 'handler-show-fullscreen-calendar'
            }"
            @handler-refresh="handlerRefresh"
            @handler-show-filter="handlerShowFilter"
            @handler-show-form-save="handlerShowSavedForm"
            @handler-show-fullscreen-calendar="handlerShowFullscreenCalendar"
          />

          <div class="card-body container-pager-grid">
              <Pager 
                :pagination="pagination"
                :records-count="records.length"
                @handler-previous-page="handlerPreviousPage"
                @handler-next-page="handlerNextPage"
              />

              <CustomGrid
                :visible="!sending"
                :titles="grid.titles"
                :mapping="grid.mapping"
                :records="recordsWithoutSundays"
                :pagination="pagination"
                :permission="$root.data.accessList.generalParameters.holidays.permission"
                @handler-show-form-save="handlerShowSavedForm"
                @handler-show-confirmation-delete="handlerShowConfirmationDelete"
              />              
          </div>

      </div>

      <ApiInteractionNotifier
        v-if="sending"
        :endpoint="httpConsumerData.endpoint"
        :httpMethod="httpConsumerData.method"
        :requestBody="httpConsumerData.requestBody"
        :with-confirmation="httpConsumerData.withConfirmation"

        @handler-web-service-response="handlerWebServiceResponse"
      />

      <!-- Modal Set -->
      <ConfirmationDelete :visible="showConfirmationDelete" :item="documentObj" @handler-hide="handlerHideConfirmationDelete" @handler-delete="handlerDelete" />
      <SavedForm :visible="showSavedForm" :item="documentObj" :error-message="errorMessage" @handler-visibility="handlerHideSavedForm" @handler-save="handlerSave" />
      <YearCalendar :visible="showFullscreenCalendar" :active-dates="activeDates" @handler-day-selector="handlerDaySelector" @handler-hide-view-calendar="handlerHideFullscreenCalendar"/>
      <Notification v-if="!hide || confirmationMessage" :loading="sending" :message="confirmationMessage" />
      <!-- End Modal Set -->

  </div>
</template>

<script>
import Headboard from '../../../Widgets/Headboard.vue'
import Pager from '../../../Widgets/Pager.vue'
import CustomGrid from '../../../Widgets/CustomGrid.vue'
import ConfirmationDelete from './Forms/ConfirmationDelete.vue'
import SavedForm from './Forms/SavedForm.vue'
import YearCalendar from './YearCalendar.vue'
import ApiInteractionNotifier from '../../../Widgets/ApiInteractionNotifier.vue'
import Notification from '../../../Widgets/Notification.vue'
export default {
    name: 'Holidays',
    components: {
      Headboard,
      Pager,
      CustomGrid,
      ConfirmationDelete,
      SavedForm,
      YearCalendar,
      ApiInteractionNotifier,
      Notification
    },
    data() {
      return {
        roles: [],
        records: [],
        recordsWithoutSundays: [],
        activeDates: [],
        documentObj: {
          id: '',
          day: '',
          isSunday: false
        },
        httpConsumerData: {
          endpoint: '',
          method: '',
          withConfirmation: false,
          requestBody: {}
        },
        confirmationMessage: '',
        errorMessage: '',
        hide: false,
        sending: false,
        showFullscreenCalendar: false,
        showConfirmationDelete: false,
        showSavedForm: false,
        pagination: {
          currentStart: 0,
          currentEnd: 0,
          total: 0,
          currentPage: 1,
          totalPage: 1,
          limit: 366
        },
        grid: {
          titles: [{name: 'Fecha'}, {name: 'Periodo'}, {name: 'Acciones', center: true}],
          mapping: [
            {
              ref: 'day',
              class: 'text-danger font-weight-bold'
            },
            {
              mode: 'HIGHLIGHTED',
              ref: 'period',
              class: 'badge badge-info'
            },
            {
              mode: 'ACTIONS',
              center: false,
              actions: [
                {
                  type: 'DELETE',
                  tooltip: 'Eliminar',
                  handler: 'handler-show-confirmation-delete'
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.consultBuilder()
    },
    methods: {
      handlerWebServiceResponse(callback) {
        this.sending = false
        if (callback.withConfirmation) {
          this.confirmationMessage = callback.response.message
          setTimeout(() => {
            this.confirmationMessage = ''
          }, 2500)
        }

        if (callback.endpoint.indexOf('/list') > -1) {
          this.pagination.currentPage = callback.response.currentPage
          this.pagination.totalPages = callback.response.totalPages
          this.pagination.currentStart = ((this.pagination.limit * (this.pagination.currentPage - 1)) + 1)
          this.pagination.currentEnd = ((this.pagination.limit * (this.pagination.currentPage - 1)) + callback.response.recordsWithoutSundays.length)
          this.pagination.total = callback.response.totalRecords
          this.records = callback.response.records
          this.recordsWithoutSundays = callback.response.recordsWithoutSundays
          this.fillActiveDates(this.records)
        } else {
          this.consultBuilder()
        }
      },
      handlerRefresh() {
        this.pagination.currentPage = 1
        this.consultBuilder()
      },
      handlerPreviousPage() {
        this.pagination.currentPage -= 1
        this.consultBuilder()
      },
      handlerNextPage() {
        this.pagination.currentPage += 1
        this.consultBuilder()
      },
      handlerHideSavedForm() {
        this.showSavedForm = false
      },
      handlerShowSavedForm(item, withSelection = true) {
        this.errorMessage = ''
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          day: (item && item.day) ? item.day : '',
          isSunday: (item && item.isSunday)
        }
        if (withSelection)
          this.documentObj.withSelection = true

        this.showSavedForm = true
      },
      handlerSave() {
        this.errorMessage = ''
        if (this.existsDay(this.documentObj.day)) {
          this.errorMessage = 'Este festivo ya fue creado previamente.'
          return
        }
        if (this.$root.utils.isSunday(this.documentObj.day)) {
          this.errorMessage = 'No es posible crear los domingos como días festivos.'
          return
        }
        this.handlerHideSavedForm()
        this.saveBuilder(this.documentObj)
      },
      handlerHideConfirmationDelete() {
        this.showConfirmationDelete = false
      },
      handlerShowConfirmationDelete(item) {
        this.documentObj = {
          id: (item && item._id) ? item._id : '',
          day: (item && item.day) ? item.day : '',
          isSunday: (item && item.isSunday) ? true : false
        }
        this.showConfirmationDelete = true
      },
      handlerDelete() {
        this.handlerHideConfirmationDelete()
        this.deleteBuilder(this.documentObj.id)
      },
      handlerHideFullscreenCalendar() {
        this.showFullscreenCalendar = false
      },
      handlerShowFullscreenCalendar() {
        this.showFullscreenCalendar = true
      },
      fillActiveDates(records) {
        this.activeDates = []
        records.forEach((r) => {
          this.activeDates.push({
            date: r.date,
            className: 'red'
          })
        })
      },
      handlerDaySelector(obj) {
        let item = {
          _id: '',
          day: '',
          isSunday: this.$root.utils.isSunday(obj.date)
        }
        if (obj.selected) {
          item.day = obj.date
          this.handlerShowSavedForm(item, false)
        } else {
          this.records.some((r) => {
            if (r.date === obj.date) {
              item._id = r._id
              item.day = r.day,
              item.isSunday = this.$root.utils.isSunday(r.date)
            }
          })
          console.log(item)
          this.handlerShowConfirmationDelete(item)
        }
      },
      existsDay(date) {
        return this.records.some((e) => {
          if (e.date === date) {
            return true
          }
        })
      },

      consultBuilder() {
        this.hide = true
        this.httpConsumerData = {
          endpoint: '/holiday/manager/list',
          method: 'post',
          withConfirmation: false,
          requestBody: {
            page: this.pagination.currentPage,
            limit: this.pagination.limit
          }
        }
        this.sending = true
      },

      saveBuilder(item) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/holiday/manager/',
          method: 'post',
          withConfirmation: true,
          requestBody: {
            documentId: (item && item.id) ? item.id : '',
            day: item.day
          }
        }

        this.sending = true
      },

      deleteBuilder(id) {
        this.hide = false
        this.httpConsumerData = {
          endpoint: '/holiday/manager',
          method: 'delete',
          withConfirmation: true,
          requestBody: {
            documentId: id
          }
        }

        this.sending = true
      }

    }
}
</script>

<style>

</style>
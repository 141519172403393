<template>
	<div class="indicator-container" :class="[{'bg-current': isCurrentPeriodActive() }, {'bg-previous': !isCurrentPeriodActive() }]">
        <div>
            <span v-if="isCurrentPeriodActive()" class="current-indicator-status">ACTUAL</span>
            <span v-else class="previous-indicator-status">PREVIO</span>
        </div>
        <div>
            <i class="far fa-calendar pr-1 color-gris-normal"></i>
            <span class="year-text">{{ getPeriodYear() }}</span>
        </div>
        <div>
            <i class="far fa-calendar-alt pr-1 color-gris-normal"></i>
            <span class="year-text">{{ getPeriodRange() }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PeriodIndicatorWidget',
    methods: {
        isCurrentPeriodActive() {
            return !(localStorage.periodOnlyReadMode && localStorage.periodOnlyReadMode !== undefined)
        },
        getPeriodYear() {
            if (this.isCurrentPeriodActive()) {
                if (localStorage.currentPeriodInfo && localStorage.currentPeriodInfo !== undefined) {
                    const obj = JSON.parse(localStorage.currentPeriodInfo)
                    return obj.year;
                }
            } else {
                if (localStorage.previousPeriodInfo && localStorage.previousPeriodInfo !== undefined) {
                    const obj = JSON.parse(localStorage.previousPeriodInfo)
                    return obj.year;
                }
            }
            return "";
        },
        getPeriodRange() {
            if (this.isCurrentPeriodActive()) {
                if (localStorage.currentPeriodInfo && localStorage.currentPeriodInfo !== undefined) {
                    const obj = JSON.parse(localStorage.currentPeriodInfo)
                    return `${obj.from} - ${obj.to}`;
                }
            } else {
                if (localStorage.previousPeriodInfo && localStorage.previousPeriodInfo !== undefined) {
                    const obj = JSON.parse(localStorage.previousPeriodInfo)
                    return `${obj.from} - ${obj.to}`;
                }
            }
            return "";
        }
    }
}
</script>

<style scoped>
.indicator-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 80%;
    padding: 3px;
    border: 1px solid rgb(220, 224, 228);
    border-radius: 4px;
}
.year-text {
    font-size: 90%;
    font-weight: bold;
}
.bg-current {
    border: 1px solid rgb(133, 243, 175);
    background-color: rgb(224, 250, 234);
}
.bg-previous {
    border: 1px solid rgb(125, 188, 251);
    background-color: rgb(201, 226, 252);
}
.current-indicator-status {
    background-color: #05ce05;
    color: white;
    padding: 2px;
    border: 0.5px solid #05ce05;
    border-radius: 3px;
}
.previous-indicator-status {
    background-color: rgb(83, 146, 255);
    color: white;
    padding: 2px;
    border-radius: 3px;
}
</style>
<template>
    <div v-if="(loading || message)">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="row justify-content-center">
                            <div v-show="loading">
                                <i class="fa fa-spinner girar color-white" style="font-size: 200%"></i> <span class="color-white" style="font-size: 22px; font-weight: bold; margin-left: 5px;">{{waitingMessage}}</span>
                            </div>
                            <div v-show="(message !== '')">
                                <div v-if="!withError" class="alert alert-success" role="alert">
                                    <i class="fas fa-check-circle color-verde-intermedio" style="font-size: 20px;"></i> <span class="color-verde-intermedio" style="font-size: 20px; font-weight: normal; margin-left: 5px;">{{ message }}</span>
                                </div>
                                <div v-else class="alert alert-danger" role="alert">
                                    <i class="fas fa-exclamation-triangle color-rojo-fuerte" style="font-size: 20px;"></i> <span class="color-rojo-fuerte" style="font-size: 20px; font-weight: normal; margin-left: 5px;">{{ message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Notification',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        waitingMessage: {
            type: String,
            default: 'Por favor espere...',
            required: false
        },
        withError: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: '',
            required: false
        }
    },
    mounted() {
        console.log('withError', this.withError);
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>
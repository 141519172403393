<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form>
                                <div class="modal-body">
                                    <p class="color-gris-intermedio">
                                        <i class="fas fa-filter"></i>
                                        <b class="color-gris"> Filtros para la búsqueda</b>
                                    </p>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span class="color-gris">Código</span>
                                                <input :disabled="filters.name !== ''" v-model="filters.code" type="text" class="form-control form-control-sm custom-input-group" placeholder="Código del centro de costo">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span class="color-gris">Nombre</span>
                                                <input :disabled="filters.code !== ''" v-model="filters.name" type="text" class="form-control form-control-sm custom-input-group" placeholder="Nombre del centro de costo">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span class="color-gris">Estado:</span>
                                                <select v-model="filters.status" class="form-control form-control-sm" required>
                                                    <option value=''>--Todos--</option>
                                                    <option value='ACTIVO'>ACTIVOS</option>
                                                    <option value='INACTIVO'>INACTIVOS</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="onShowFilter()">Cerrar</button>
                                    <button @click.prevent="onResetFilter()" type="button" class="btn btn-danger btn-sm">
                                        <i class="fas fa-minus-circle"></i> Resetear
                                    </button>
                                    <button @click.prevent="onApplyFilter()" type="button" class="btn btn-success btn-sm">
                                        <i class="fas fa-check-circle"></i> Filtrar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!-- End Filters Modal -->
</template>

<script>
export default {
    name: 'CostCenterFilteredOutForm',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object,
            defaul() {
                return {
                    code: '',
                    name: '',
                    status: ''
                }
            }
        }
    },
    methods: {
        onShowFilter() {
            this.$emit('handler-show-filter', false)
        },
        onResetFilter() {
            this.$emit('handler-reset-filter')
        },
        onApplyFilter() {
            this.$emit('handler-apply-filter')
        }
    }
    
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 39, 39, 0.5);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: initial;
    }

    /** Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /** End Transitions */
</style>